<!-- AdviceSection.vue -->
<template>
  <div class="advice-section" ref="adviceSection">
    <!-- Section title -->
    <div class="section-title">
      <h2 v-underline-primary>{{ h2 }}</h2>
      <p>{{ subtitle }}</p>
      <div v-if="model_used || iteration_used" class="model-iteration"> ℹ️👷 Public Beta: Iteration: {{ iteration_used }} ({{ model_used }})</div>
    </div>
    <!-- Display sticky notes if there are any results -->
    <div v-if="localResult && localResult.length > 0" class="sticky-notes">
      <div
        v-for="(r, i) in localResult"
        :key="i"
        @click="selectStickyNote(i)"
        :class="{ selected: selectedStickyNote === i }"
        class="sticky-note"
        :style="{
          backgroundImage:
            'linear-gradient(to bottom, rgba(248, 231, 180, 0.85), var(--color-stickynote)), url(' +
            r.thumbnail +
            ')',
          backdropFilter: 'blur(10px)',
        }"
      >
        <p v-if="r.category" class="category">
          <span>{{ r.category }} {{ getCategoryEmoji(r.category) }}</span>
        </p>
        <p v-if="r.advice">{{ r.advice }}</p>
        <p v-if="!r.advice && !r.title">{{ r }}</p>

        <div v-if="r.video_id" class="video-info">
          <!-- <div class="thumbnail" ></div> -->
          <div class="details">
            <p class="title">{{ r.title }} | {{ r.channel }}</p>
            <p class="duration">
              {{ r.duration_string }} | {{ r.like_count }} likes
            </p>
            <!-- <p v-if="r.like_count" class="likes">{{ r.like_count }} likes</p> -->
          </div>
        </div>
        <!-- Emit the update-rating event from this component -->
        <div class="sticky-bottom">
          <StarRating
            v-model="localRatings[i]"
            :index="i"
            :uniqueID="
              r.video_id ? `video_${r.video_id}` : `advice_${r.record_id}`
            "
            @input="updateRating(i, $event)"
          />
          <div class="rating-average">
            <span class="rating-symbol">&#248;</span>
            {{
              r.rating_amount > 0
                ? (r.rating / r.rating_amount).toFixed(1)
                : "-"
            }}
            stars ({{ r.rating_amount }} ratings)
          </div>
          <!-- <StarRating v-model="localRatings[i]" :index="i" @input="updateRating(i, $event)" /> -->
          <!-- add ratingId to the StarRating component -->
          <!-- make a function for the uniqueID that i can use in methods and just call from here           -->
          <a
            v-if="r.video_id"
            :href="'https://www.youtube.com/watch?v=' + r.video_id"
            target="_blank"
            class="youtube-link"
          >
            <i class="fab fa-youtube"></i> Watch</a
          >
        </div>
      </div>
      <!-- 
      <button class="add-note" @click="addNote">
        <i class="fas fa-plus-circle"></i> Add Note
      </button> -->
    </div>
    <!-- Display a message if there are no results -->
    <div v-else class="no-results">
      <p>No advice found</p>
    </div>
  </div>
</template>

<script>
import StarRating from "./StarRating.vue";

export default {
  name: "AdviceSection",
  components: {
    StarRating,
  },
  props: {
    h2: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    ratings: {
      type: Array,
      required: true,
      default: () => [],
    },
    result: {
      type: Array,
      required: true,
      default: () => [],
    },
    model_used: {
      type: String,
      default: null,
    },
    iteration_used: {
      type: String,
      default: null,
    },

  },

  data() {
    return {
      selectedStickyNote: null,
      localRatings: this.ratings.slice(),
      localResult: this.result.result
        ? this.result.result.slice()
        : this.result.slice(),
    };
  },
  computed: {
    averageRating() {
      const totalRatings = this.localResult.reduce(
        (acc, curr) => acc + curr.rating,
        0
      );
      const totalRatingsAmount = this.localResult.reduce(
        (acc, curr) => acc + curr.rating_amount,
        0
      );
      return totalRatingsAmount > 0
        ? (totalRatings / totalRatingsAmount).toFixed(1)
        : "-";
    },
  },

  mounted() {},
  watch: {
    result: {
      immediate: true, // trigger the watcher immediately when the component is created
      handler(newResult) {
        if (newResult.some((item) => item.video_id)) {
          this.fetchVideoDetails();
        }
        if (newResult.some((item) => item.record_id)) {
          this.fetchAdviceDetails();
        } else {
          this.localResult = newResult;
        }
      },
    },
  },

  methods: {
    // Method to select a sticky note and emit the select-sticky-note event
    selectStickyNote(index) {
      this.selectedStickyNote = index;
      this.$emit("select-sticky-note", { index, rating: this.ratings[index] });
    },

    getCategoryEmoji(category) {
      const emojis = {
        "budget friendly": "💰",
        "fast to achieve": "🚀",
        "best practice": "🏆",
        "super creative": "🎨",
        "citizen action": "🕺",
        "municipality action": "🏛️",
      };
      return emojis[category.toLowerCase()] || "";
    },
    async fetchVideoDetails() {
      const videoIds = this.result
        .filter((item) => item.video_id)
        .map((item) => item.video_id);

      for (const videoId of videoIds) {
        const record = await this.$pb
          .collection("video_db")
          .getFirstListItem(`video_id="${videoId}"`);
        // console.log('record', record);
        const index = this.result.findIndex(
          (item) => item.video_id === videoId
        );
        if (index >= 0 && record) {
          // Merge the fetched record properties with the existing result object
          this.localResult[index] = { ...this.result[index], ...record };
          this.$emit("update:result", this.localResult);
        }
      }
    },
    async fetchAdviceDetails() {
      const recordIds = this.result
        .filter((item) => item.record_id)
        .map((item) => item.record_id);
      for (const recordId of recordIds) {
        try {
          const record = await this.$pb
            .collection("infrastructure_recs")
            .getOne(recordId, {});
          const index = this.result.findIndex(
            (item) => item.record_id === recordId
          );
          if (index >= 0 && record) {
            // Merge the fetched record properties with the existing result object
            this.localResult[index] = { ...this.result[index], ...record };
            this.$emit("update:result", this.localResult);
          }
        } catch (error) {
          console.error(
            `Error fetching advice details for record ${recordId}:`,
            error
          );
        }
      }
    },
    // Method to update the rating for a sticky note
    updateRating(index, value) {
      this.localRatings[index] = value;
      this.$emit("update:ratings", this.localRatings);

      const currentItem = this.localResult[index];

      // Update rating and rating amount in video_db record if it exists
      const videoId = currentItem.id;
      if (videoId) {
        this.$pb
          .collection("video_db")
          .update(videoId, {
            rating: (currentItem.rating || 0) + value,
            rating_amount: (currentItem.rating_amount || 0) + 1,
          })
          .catch((error) => {
            console.error(
              `Error updating rating in video_db record for video ${videoId}:`,
              error
            );
          });
      }
      const recordId = currentItem.record_id;
      // Update rating and rating amount in infrastructure_recs record if it exists
      if (recordId) {
        this.$pb
          .collection("infrastructure_recs")
          .update(recordId, {
            rating: (currentItem.rating || 0) + value,
            rating_amount: (currentItem.rating_amount || 0) + 1,
          })
          .catch((error) => {
            console.error(
              `Error updating rating in infrastructure_recs record for record ${recordId}:`,
              error
            );
          });
      }
    },
  },
};
</script>

<style scoped>
.sticky-notes {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 32px 0;
  gap: 20px;
}

.sticky-note {
  display: flex;
  flex-direction: column;
  background-color: var(--color-stickynote);
  border-radius: 10px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  gap: 12px;
  width: 300px;
  min-width: 240px;
  max-width: 320px;
  overflow-wrap: break-word;
  opacity: 0;
  animation-name: fade-in;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  background-position: center bottom 63%;
  background-size: cover;
  color: var(--color-text-dark);
}

.sticky-note:nth-child(2) {
  animation-delay: 0.5s;
}

.sticky-note:nth-child(3) {
  animation-delay: 1s;
}

.sticky-note.selected {
  background-color: var(--color-primary);
  /* color: #FFFFFF; */
}
.sticky-bottom {
  margin-top: auto;
  display: flex;
  gap: 12px;
  justify-content: space-evenly;
  flex-direction: column;
}

.dark-mode .sticky-note p {
  color: var(--color-text-dark);
}

.category {
  margin: -32px 0 0;
  display: flex;
  font-size: 0.7rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  color: var(--color-secondary);
  position: relative;
  justify-content: center;
}

.category span {
  background-color: #fffffff0;
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  z-index: 1;
}

.category::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0.5rem 1rem 0.5rem;
  border-color: transparent transparent var(--color-stickynote) transparent;
  z-index: 0;
}

.favorite {
  position: absolute;
  top: 0;
  font-size: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  color: var(--color-primary);
  background-color: white;
  border: 4px solid;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 92px rgba(255, 255, 255, 1.1);
}

.favorite i {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 20px;
  transform: translate(-50%, -50%);
  animation-name: pulse;
  animation-duration: 1s;
  animation-iteration-count: 1;
}
.dark-mode .favorite {
  /* color: var(--color-primary-dark); */
  /* background-color: var(--color-primary); */
  background-color: white;
  box-shadow: none;

  border: 0;
}

.youtube-link {
  padding: 10px;
  /* background-color: red; */
  background-color: #fffffff2;
  color: #424242;
  border-radius: 5px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.youtube-link i {
  margin-right: 10px;
  /* color: var(--color-secondary); */
}

.star-rating {
  display: inline-flex;
  font-size: 20px;
  align-self: center;
}


.star-rating span {
  cursor: pointer;
  margin-right: 5px;
  color: #ccc;
}

.star-rating span.active {
  color: #ffc107;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

/* mobile styles */

@media screen and (max-width: 600px) {
  .sticky-notes {
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
  }

  /* scale down the advice section */
  .advice-section {
    transform: scale(0.9);
    margin-top: -32px;
    margin-bottom: -32px;
  }

  .sticky-note {
    width: 100%;
    max-width: 400px;
  }
}
</style>
