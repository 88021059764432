<template>
  <div v-if="errorMessage" class="error-banner">
    <p>{{ errorMessage }}</p>
  </div>
</template>

<script>
export default {
  props: {
    errorMessage: String,
  },
};
</script>


<style scoped>
.error-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 12px;
  background-color: var(--color-error);
  color: white;
  text-align: center;
  font-weight: bold;
  z-index: 1000;
  background-position: center;
  background-size: cover;
  animation: slide-in 1s;
}

@keyframes slide-in {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
</style>
