<!-- ViewSubmission.vue - page displays a single submission -->
<template>
  <div class="submission-details container content">
    <div class="overlay" v-if="showShareReport" @click="closeOverlay">
      <div class="share-report">
        <button class="close-button" @click="toggleShareReport">
          <i class="fas fa-times"></i>
        </button>
        <ShareReport :submission="submission" @close="toggleShareReport" />
      </div>
    </div>

    <div class="submission-header">
      <div class="left button-wrapper">
        <router-link to="/explore">
          <button class="btn-round">
            <i class="fas fa-chevron-left"></i>
          </button>
        </router-link>
        <button class="btn-round" @click="toggleShareReport">
          <i class="fas fa-share"></i>
        </button>
        <button class="btn-round" @click="voteSubmission('upvotes')">
          <span style="font-size: smaller">{{ submission.upvotes }}</span>
          <i class="fas fa-arrow-up"></i>
        </button>
        <button class="btn-round" @click="voteSubmission('downvotes')">
          <span style="font-size: smaller">{{ submission.downvotes }}</span>
          <i class="fas fa-arrow-down"></i>
        </button>
      </div>
      <blockquote class="submission-comment">
        <div class="image-card-container">
          <div
            v-for="(imageUrl, index) in submission.imageUrls"
            :key="index"
            class="image-card"
            @click="selectedImageIndex = index"
          >
            <img :src="imageUrl" alt="" />
          </div>

          <div
            v-if="selectedImageIndex !== null"
            class="overlay"
            @click="closeImageOverlay"
          >
            <div class="image-overlay-content" @click.stop>
              <div class="image-container">
                <img :src="submission.imageUrls[selectedImageIndex]" alt="" />
              </div>
              <div class="image-navigation">
                <div class="arrow left" @click="previousImage">
                  <i class="fas fa-chevron-left"></i>
                </div>
                <div class="arrow right" @click="nextImage">
                  <i class="fas fa-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p>{{ submission.comment }}</p>
        <div class="submission-details-item">
          <a
            :href="
              submission.geo
                ? `https://www.openstreetmap.org/?mlat=${
                    submission.geo.split(',')[0]
                  }&mlon=${submission.geo.split(',')[1]}&zoom=14`
                : ''
            "
            target="_blank"
          >
            🗺️
            <span v-if="submission.address">
              {{
                submission.address
                  ? submission.address.split(",")[0] +
                    ", " +
                    submission.address.split(",")[1] +
                    ", "
                  : ""
              }}
            </span>
            <span v-if="submission.city">{{ submission.city }}, </span>
            <span>{{ submission.region }}</span
            >,
            <span>{{ submission.country }}</span>
          </a>
        </div>
        <div class="submission-comment-head">
          🪪
          <p v-underline-primary>{{ submission.submission_id }}</p>
          🚥 Severity:
          <SeverityLabel :severity="submission.severity_level" />
        </div>
      </blockquote>
    </div>

    <div class="submission-details-box">
      <div class="submission-details-item">
        <p class="submission-details-label">🏷️ Labels:</p>
        <LabelsWrapper :labels="submission.all_labels" :readonly="true" />
      </div>
    </div>

    <AdviceSection
      :h2="'Action-ideas by OpenAI 🤖'"
      :subtitle="'Here are some ideas for action based on the submission.'"
      :result="submission.advice_array"
      :iteration_used="submission.iteration_used"
      :model_used="submission.model_used"
      @update:result="result = $event"
    />

    <blockquote class="submission-comment content">
      <p v-html="addExternalLinkLabels(submission.advice_comment)"></p>
    </blockquote>
    <CategoryRating
      v-for="(rating, index) in categoryRatings"
      :key="index"
      :category="rating.category"
      :description="rating.description"
      :id="submission.id"
    />

    <CyclingConditions
      :city="submission.city"
      :cyclingConditions="submission.cyclingConditions"
    />

    <AdviceSection
      :h2="'Curated Content 📼'"
      :subtitle="'Get inspired by some video content based on the submission.'"
      :result="this.contentRecs.concat(submission.content_array)"
      @update:result="result = $event"
    />

    <div class="shuffle-button-wrapper" style="margin-bottom: 48px;">
    <button @click="shuffleToNextSubmission(submission.geo)" class="btn-cta">
      <i class="fas fa-random"></i>
       Shuffle to next submission
    </button>
  </div>
  </div>

  <!-- shuffle button to view another submission -->


  
</template>

<script>
import AdviceSection from "@/components/AdviceSection.vue";
import LabelsWrapper from "@/components/LabelsWrapper.vue";
import CategoryRating from "@/components/CategoryRating.vue";
import SeverityLabel from "@/components/SeverityLabel.vue";
import CyclingConditions from "@/components/CyclingConditions.vue";
import ShareReport from "@/components/ShareReport.vue";
import axios from "axios";

// core version + navigation, pagination modules:

export default {
  name: "ViewSubmission",
  components: {
    AdviceSection,
    LabelsWrapper,
    CategoryRating,
    SeverityLabel,
    CyclingConditions,
    ShareReport,
  },
  setup() {},

  data() {
    return {
      submission: {
        upvotes: 0,
        downvotes: 0,
      },
      showShareReport: false,
      showCyclingConditions: false,
      submissionId: this.$route.params.id,
      selectedImageIndex: null,
      contentRecs: [],
      categoryRatings: [
        {
          category: "Creativity",
          description: "creativity and novelty of the proposed solution",
        },
        {
          category: "Effectiveness",
          description:
            "potential impact and efficiency of the proposed solution in addressing the reported issue",
        },
        {
          category: "Feasibility",
          description:
            "considering available resources, current infrastructure, and policy constraints",
        },
      ],
    };
  },
  computed: {
    filteredCyclingConditions() {
      // Create a new object where keys starting with 'BIC_' are excluded
      let filtered = Object.keys(this.submission.cyclingConditions).reduce(
        (result, key) => {
          if (!key.startsWith("BIC_")) {
            result[key] = this.submission.cyclingConditions[key];
          }
          return result;
        },
        {},
      );

      return filtered;
    },
  },

  mounted() {
    this.setMetaTags();

    this.fetchSubmissionDetails().catch((error) => {
      console.error(error);
    });
    document.addEventListener("keydown", this.handleKeyDown);

    // wait for submission to be fetched, then update meta tags
    this.$watch(
      () => this.submission,
      () => {
        this.setMetaTags(
          this.submission.city + ", " + this.submission.country,
          this.submission.address,
        );
      },
    );

    // this.fetchAdvice();
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  },

  beforeRouteUpdate(to, from, next) {
  const newSubmissionId = to.params.id;
  if (newSubmissionId && newSubmissionId !== this.submissionId) {
    this.submissionId = newSubmissionId;  // update submissionId in data
    this.fetchSubmissionDetails().catch(error => {
      console.error(error);
    });
  }
  next();
},


  methods: {
    setMetaTags(title, description) {
      // Set the document title
      document.title = this.$route.meta.title;

      // Set or update the meta description
      let metaDescription = document.querySelector('meta[name="description"]');
      if (!metaDescription) {
        metaDescription = document.createElement("meta");
        metaDescription.name = "description";
        document.head.appendChild(metaDescription);
      }
      metaDescription.content = this.$route.meta.description;
      // add country to meta description
      if (title && description) {
        document.title = this.$route.meta.title + " - " + title;
        metaDescription.content =
          this.$route.meta.description + " - " + description;
      }
      // ...
    },

    toggleCyclingConditions() {
      this.showCyclingConditions = !this.showCyclingConditions;
    },
    toggleShareReport() {
      this.showShareReport = !this.showShareReport;
    },
    closeOverlay() {
      if (event.target.classList.contains("overlay")) {
        this.showShareReport = false;
      }
    },
    closeImageOverlay(event) {
      if (event.target.classList.contains("overlay")) {
        this.selectedImageIndex = null;
      }
    },
    async fetchSubmissionDetails() {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_PB_URL}/api/collections/reports/records?filter=submission_id='${this.submissionId}'`,
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        this.submission = data.items[0];
        this.fetchImageDetails(this.submission.id);
        if (this.submission.country === "Germany") {
          // const geo = this.submission.geo.split(',');

          this.submission.cyclingConditions = await this.fetchCyclingConditions(
            this.submission.city,
          );
          // this.submission.cyclingConditions = await this.fetchCyclingConditions(geo);
          if (!this.submission.cyclingConditions) {
            const geo = this.submission.geo.split(",");
            this.submission.cyclingConditions =
              await this.fetchCyclingConditions(geo);
            // make the geo a string - it gets stripped out otherwise
          }
        }
        this.getContentRecs();
      } catch (e) {
        console.error(e);
      }
    },
    async fetchCyclingConditions(location) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_SERVER_URL}/cycling-conditions/${location}`,
        );
        let cyclingConditionsString = response.data;

        // Replace NaN values with null
        cyclingConditionsString = cyclingConditionsString.replace(
          /NaN/g,
          "null",
        );

        const cyclingConditions = JSON.parse(cyclingConditionsString);
        return cyclingConditions;
      } catch (error) {
        console.error(error);
      }
    },
    async shuffleToNextSubmission(geo) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_SERVER_URL}/shuffle`,
          { geo: geo }  // Update this to use dynamic coordinates as needed
        );

        if (response.data && response.data.submissionId) {
          this.$router.push(`/view/${response.data.submissionId}`);
          
          
          
          // scroll to top of page
          window.scrollTo(0, 0);
        } else {
          console.error('Unexpected API response:', response.data);
        }
      } catch (error) {
        console.error('Error fetching submission ID:', error);
      }
    },
    getRatingClass(rating) {
      if (rating <= 1.0) {
        return "excellent";
      } else if (rating <= 2.0) {
        return "good";
      } else if (rating <= 3.0) {
        return "average";
      } else if (rating <= 4.0) {
        return "poor";
      } else {
        return "bad";
      }
    },
    async getContentRecs() {
      try {
        // const content = `${this.submission.comment}`;
        // get content recs for best practices (advice in array index 1)
        const content = `${this.submission.comment} ${this.submission.advice_array[1].advice}`;
        console.log(content);
        const response = await axios.post(
          `${process.env.VUE_APP_SERVER_URL}/get-content-recs`,
          {
            content: JSON.stringify(content),
          },
        );
        const newContentRecs = response.data;

        // Remove duplicates from video_id field
        const uniqueContentRecs = newContentRecs.filter((item) => {
          const existingItem = this.submission.content_array.find(
            (existing) => existing.video_id === item.video_id,
          );
          return !existingItem;
        });

        this.contentRecs = uniqueContentRecs;
        return uniqueContentRecs;
      } catch (error) {
        console.error(error);
        return null;
      }
    },

    async fetchImageDetails(id) {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_PB_URL}/api/collections/images/records?filter=report='${id}'`,
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        const images = data.items[0].images;
        const imageUrls = images.map(
          (image) =>
            `${process.env.VUE_APP_PB_URL}/api/files/${data.items[0].collectionId}/${data.items[0].id}/${image}`,
        );
        this.submission.imageUrls = imageUrls;
        this.generateAndUploadThumbnail(imageUrls[0], this.submission.id);

        // console.log(imageUrls);
        return imageUrls;
      } catch (e) {
        console.error(e);
      }
    },
    async generateAndUploadThumbnail(imageUrl, recordId) {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = imageUrl;
      await new Promise((resolve) => {
        img.onload = resolve;
      });
      const canvas = document.createElement("canvas");
      canvas.width = 50;
      canvas.height = 50;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, 50, 50);
      const thumbnail = canvas.toDataURL("image/jpeg", 0.5);
      const file = this.dataURItoBlob(thumbnail);
      const formData = new FormData();
      formData.append("image", file, "thumbnail.jpg");
      const collection = this.$pb.collection("reports");
      const record = await collection.update(recordId, null, {
        files: formData,
      });
      this.submission.image = record.image;
    },

    dataURItoBlob(dataURI) {
      const base64 = dataURI.split(",")[1];
      const binaryString = window.atob(base64);
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return new Blob([bytes], { type: "image/jpeg" });
    },

    async voteSubmission(voteType) {
      let updatedCount;
      if (voteType === "upvotes") {
        updatedCount = this.submission.upvotes + 1;
      } else if (voteType === "downvotes") {
        updatedCount = this.submission.downvotes + 1;
      } else {
        throw new Error("Invalid vote type");
      }
      const updatedRecord = await this.$pb
        .collection("reports")
        .update(this.submission.id, {
          upvotes:
            voteType === "upvotes" ? updatedCount : this.submission.upvotes,
          downvotes:
            voteType === "downvotes" ? updatedCount : this.submission.downvotes,
        });
      // print(updatedRecord);
      this.submission.upvotes = updatedRecord.upvotes;
      this.submission.downvotes = updatedRecord.downvotes;
    },

    previousImage() {
      if (this.selectedImageIndex > 0) {
        this.selectedImageIndex--;
      }
    },
    nextImage() {
      if (this.selectedImageIndex < this.submission.imageUrls.length - 1) {
        this.selectedImageIndex++;
      }
    },
    handleKeyDown(event) {
      if (event.key === "Escape") {
        this.selectedImageIndex = null;
      } else if (event.key === "ArrowLeft") {
        this.previousImage();
      } else if (event.key === "ArrowRight") {
        this.nextImage();
      }
    },
    handleTouchStart(event) {
      this.touchStartX = event.changedTouches[0].clientX;
    },

    addExternalLinkLabels(comment) {
      const regex =
        /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
      return comment.replace(regex, (match) => {
        const url = new URL(match);
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">🔗 external link (${url.hostname})</a>`;
      });
    },
  },
};
</script>

<style scoped>
/* h2 {
    margin: -48px 0px 0px -48px;
  } */
/* Style for the swiper container */

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #999;
  font-size: 20px;
  cursor: pointer;
}

.submission-comment {
  margin: 0;
  margin-top: 20px;

  flex: auto;
  word-wrap: break-word;
  overflow-wrap: break-word;

  border-radius: 12px;

  padding: 32px;

  background-color: var(--color-background);
  /* border-left: 10px solid var(--color-primary);; */
  font-size: 18px;
  line-height: 1.5;

  text-align: center;
}
.dark-mode .submission-comment {
  background-color: var(--color-background-dark);
}

.submission-comment p {
  margin: 0;
}

.submission-comment:before {
  content: "\201C"; /* add opening quotation mark before the comment */
  font-size: 64px;
  font-weight: bold;
  line-height: 0;
  vertical-align: middle;
  text-align: justify;
  /* margin-right: 10px; */
  padding: 10px 0 0;
  color: var(--color-primary);
}

.submission-header {
  text-align: center;
  display: flex;
  flex-direction: row-reverse;
  /* align-items: center; */
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.left.button-wrapper {
  display: flex;
  flex-direction: column;
  z-index: 100;
  gap: 8px;
  align-self: center;
}

.rating {
  display: flex;
  justify-content: space-between;
}
.rating-value {
  display: inline-block;
  padding: 2px 5px;
  border-radius: 4px;
  color: #ffffff;
  font-weight: bold;
}

.rating-value-box {
  display: flex;
  gap: 8px;
}

.rating-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
}

.rating-value.excellent {
  background-color: #00cc66;
}

.rating-value.good {
  background-color: #66cc00;
}

.rating-value.average {
  background-color: #cccc00;
}

.rating-value.poor {
  background-color: #cc6600;
}

.rating-value.bad {
  background-color: #cc0000;
}

.user-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: -64px auto 0 auto;
}

.user-header img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
  object-position: center;
}
.image-card-container {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  margin-top: -100px;
  margin-right: -64px;
}

.image-card {
  width: 100px;
  height: 100px;
  margin: 0.5rem;
  border-radius: 1rem;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.image-card:hover {
  transform: scale(1.05);
}

.image-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cycling-conditions {
  /* Background image */
  /* background-image: url('.././publ/urbancyclist.jpg'); Adjust the URL here */
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top;
}
.submission-comment-head {
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  /* margin-top: -50px; */
}

.submission-details-box {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
  align-items: center;
}

.submission-location-box {
  display: flex;
  flex-direction: row;
}

.add-label-form {
  display: none;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.image-overlay-content {
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: relative;
}

.image-navigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.arrow:hover {
  background-color: rgba(255, 255, 255, 0.7);
}

.arrow i {
  color: #000;
}

.arrow.left {
  left: 20px;
}

.arrow.right {
  right: 20px;
}

.image-overlay-content img {
  width: 100%;
  height: auto;
}

@media (max-width: 767px) {
  .btn-round {
    margin-left: -40px;
  }
  .submission-header {
    gap: 8px;
  }
  .submission-details-box {
    flex-direction: column-reverse;
    text-align: center;
    align-items: center;
  }
  .submission-location-box {
    flex-direction: column;
  }
  .left.button-wrapper {
    margin-left: 12px;
  }
}
</style>
