<template>
    <div class="labels" ref="labelsWrapper">

      <span v-for="(label, index) in internalLabels" :key="index" class="label label-animate">
        <span v-if="label.amount > 0" class="amount">{{ label.amount }}</span>
        {{ label.label }}
        <span class="emoji" v-if="label.label in labelEmojiMap">{{ labelEmojiMap[label.label] }}</span>
        <i v-if="!readonly" class="remove-tag fas fa-times" @click="removeLabel(index)"></i>
      </span>
    <form v-if="!readonly" @submit.prevent="addLabel" class="add-label-form">
      <div class="dropdown" :class="{ open: dropdownOpen }">
        <input type="text" v-model="newLabel" placeholder="Add a new label" @keydown.enter.prevent="addLabel" @click="dropdownOpen = true" @focus="dropdownOpen = true">
        <div class="dropdown-content" role="menu" aria-label="Label Options">
          <a v-for="(option, index) in options" :key="index" role="menuitem" @click="selectOption(option[0])" :tabindex="dropdownOpen ? 0 : -1">
            {{ option[1] }}
            {{ option[0] }}
          </a>
        </div>
      </div>
      <button type="submit" :class="{ 'primary-button': newLabel }">✓</button>
    </form>
  </div>
 
</template>

<script>
export default {
  name: 'LabelsWrapper',
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    labels: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => [
        ['blocked bike lane', '🚲🚫'],
        ['bike lane', '🛣️'],
        ['pothole', '🕳️'],
        ['parking', '🅿️'],
        ['sidewalk', '🚶'],
        ['traffic light', '🚦'],
        ['traffic sign', '🚸'],
        ['other', '📌']
      ]
    }
  },
  data() {
    return {
      internalLabels: [],
      newLabel: '',
      dropdownOpen: false,
      labelEmojiMap: {
        'car': '🚗',
        'bicycle': '🚲',
        'motorcycle': '🏍️',
        'pothole': '🕳️',
        'traffic light': '🚦',
        'traffic sign': '🚸',
        'parking': '🅿️',
        'obstruction': '🚧',
        'blocked bike lane': '🚲🚫',
        'bike lane': '🚲🛣️',
        'puddle': '🌊',
        'person': '🚶',
        'flowers': '💐',
        'tram': '🚋',

      }

    };
  },
  mounted() {
    this.internalLabels = this.labels;
    // Add event listener to document object
    document.addEventListener('click', this.handleDocumentClick);
  },
  beforeUnmount() {
    // Remove event listener from document object
    document.removeEventListener('click', this.handleDocumentClick);
  },
  watch: {
    labels: {
      handler(newLabels) {
        this.internalLabels = newLabels;
        // Scroll to the labels section
        this.$nextTick(() => {
          // this.$refs.labelsWrapper.scrollIntoView({ behavior: 'smooth' });
        });
      },
      immediate: true
    }
  },
  methods: {
    addLabel() {
      if (this.newLabel.trim() !== '') {
        this.internalLabels.push({ label: this.newLabel.trim(), amount: 1 });
        this.newLabel = '';
        this.updateLabels();
      }
    },
    removeLabel(index) {
      this.internalLabels.splice(index, 1);
      this.updateLabels();
    },
    updateLabels() {
      this.$emit('update-labels', this.internalLabels);
    },
    selectOption(option) {
      this.newLabel = option;
      this.addLabel();
      this.dropdownOpen = false;
    },
    handleDocumentClick(event) {
      if (!this.$el.contains(event.target)) {
        this.dropdownOpen = false;
      }
    }
  }
};
</script>


<style scoped>
  form {
    display: flex;
    gap: 4px;
  }

  .dropdown a {
    color: var(--color-text);
  }
  .labels {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    animation: fade-in .5s ease-out;
    z-index: 2;
  }
  
  .labels span {
    display: inline;
    padding: 8px;
    background-color: var(--color-background);
    color: var(--color-text);
    /* color: #FFFFFF; */
    font-size: 16px;
    border-radius: 30px;
    position: relative;

  }

  .amount {
    font-weight: bold;
  }

  .labels span .remove-tag {
    position: relative;
    top: 0;
    right: 0;
    padding: 4px;
    color: var(--color-text-dark);
    cursor: pointer;
  }

  .primary-button {
    background-color: var(--color-primary);
    color: #FFFFFF;
  }

  .add-label-form input[type="text"] {
    border: 1px solid var(--color-secondary);
    padding: 8px;
    border-radius: 30px;
    padding: 10px;
    font-size: 16px;
    color: var(--color-text-dark);
    background-color: var(--color-bg-light);
  }

  .add-label-form button[type="submit"] {
    background-color: var(--color-secondary);
    color: #FFFFFF;
    font-size: 16px;
    padding: 8px 16px;
    border: 0;
    border-radius: 30px;
    cursor: pointer;
  }

  .dropdown-content {
    display: none;
    margin-top: 8px;
    /* position: absolute; */
    z-index: 1;
    background-color: #f9f9f9;
    /* min-width: 160px; */
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    padding: 12px;
  }

  .dropdown.open .dropdown-content {
    display: flex;
    flex-direction: row;
    gap: 8px;
    /* position: fixed; */
    position: absolute;

     /* top: 50%; */
    left: 50%;
    transform: translate(-50%, -200px);
    
    
  }
  
  
  .dropdown-content a {
    animation: fade-in .5s ease-out;
    /* display: block; */
    padding: 6px 0;
    text-align: center;
  }

  .dark-mode .labels span {
    background-color: var(--color-background-dark);
    color: var(--color-text-light);
  }

  .dark-mode .add-label-form input[type="text"] {
    background-color: var(--color-background-dark);
    color: white;
    border-color: #555;
  }

  .dark-mode .labels span .remove-tag {
    color: var(--color-text-light);
  }

  .dark-mode .add-label-form button[type="submit"] {
    background-color: #CCC;
    color: var(--color-background-dark);
  }

  .label-animate {
    animation: fly-in 0.5s ease-out forwards;
    opacity: 0;
    transform: translateX(-20%);
  }

  @keyframes fly-in {
    from {
      opacity: 0;
      transform: translateY(20%);
    }
    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  @media (max-width: 767px) {
    .dropdown.open .dropdown-content {
      flex-direction: column;
    }
  }
</style>
