<!-- ShareReport.vue -->
<template>
    <div class="share-report">
      <h3>Generate and share a report</h3>
      <div>
        <div class="language-select-container">
            <label for="language-select">Language:</label>
            <select id="language-select" v-model="language" class="language-select">
                <option v-for="lang in supportedLanguages" :value="lang.name" :key="lang.code">{{ lang.name }}</option>
            </select>
            </div>
            
      </div>
      <div class="share-options" style="display: flex; flex-direction: column; gap: 12px;">
        <button class="btn-standard" @click="generateReport('twitter')" :disabled="reportGenerated || !language">
          <i class="fab fa-twitter"></i> Share on Twitter
        </button>
        <!-- <button class="btn-standard disabled" @click="generateReport('municipality')" :disabled="reportGenerated || !language">
          <i class="fas fa-building"></i> Share with Municipality
        </button> -->
        <!-- add seperator line -->
        
        <button v-if="!reportGenerated" class="btn-standard" @click="copyLinkToClipboard">
      <i class="fas fa-copy"></i> Copy Link
    </button>

      </div>
      
      <div v-if="reportGenerated" class="report fade-in comment">
        <h3 v-underline-primary>Generated Report</h3>
        <div class="report-content">
          <textarea class="comment" ref="reportTextarea" rows="1" style=" height:200px" v-model="reportText" readonly></textarea>
  
          <div style="display:flex;flex-direction: row; gap:8px; justify-content: center;">
            <button class="btn-round copy-button" @click="copyToClipboard" :disabled="!reportGenerated">
              <i class="fas fa-copy"></i>
            </button>
            <button class="btn-round twitter-button" @click="postToTwitter" :disabled="!reportGenerated">
              <i class="fab fa-twitter"></i>
            </button>
          </div>
        </div>
      </div>
      
      <p v-if="reportStatus" class="status">{{ reportStatus }}</p>
      <div v-if="showSpinner" class="spinner">
        <i class="fas fa-spinner fa-spin"></i>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: "ShareReport",
    data() {
      return {
        reportGenerated: false,
        reportText: "",
        tweetURL: "",
        reportStatus: "",
        showSpinner: false,
        language: "",
        supportedLanguages: [ // Supported languages with their codes and names
        { code: "en", name: "English" },
        { code: "de", name: "German" },
        { code: "nl", name: "Dutch" },
        { code: "es", name: "Spanish" },
        { code: "it", name: "Italian" },
        { code: "fr", name: "French" },
        { code: "pt", name: "Portuguese" },
        { code: "pl", name: "Polish" },
        // { code: "ru", name: "Russian" },
        { code: "cs", name: "Czech" },
        { code: "sv", name: "Swedish" },
        { code: "fi", name: "Finnish" },
       
        // Add more languages as needed
      ]

      };
    },

    mounted () {
        const submission = this.$attrs.submission;
        
        this.language = this.supportedLanguages[0].name;
        const languageCode = submission.submission_id.toLowerCase().substring(0, 2);
        const selectedLanguage = this.supportedLanguages.find((lang) => lang.code === languageCode);

        this.language = selectedLanguage.name;
    },

    methods: {
        async generateReport(platform) {
            // Generate report based on submission data
            const submission = this.$attrs.submission;
            const report = `Submission Details:
                Comment: ${submission.comment}
                Address: ${submission.address}
                City: ${submission.city}
                Region: ${submission.region}
                Country: ${submission.country}
                Severity Level: ${submission.severity_level}
                Upvotes: ${submission.upvotes}
                Downvotes: ${submission.downvotes}`;

            // Generate report text based on platform

            // this.language = this.supportedLanguages.find(lang => lang.code === this.language.name);

            if (platform === "twitter") {
                // const twitterText = `${submission.comment} ${submission.advice_array} ${submission.advice_comment}. from ${submission.city}, ${submission.region}, ${submission.country}`;
                const twitterText = `${submission.comment} ${submission.advice_array[1].advice}. from ${submission.city}, ${submission.region}, ${submission.country}`;
                this.reportText = twitterText;
            } else if (platform === "municipality") {
                this.reportText = report;
            }

            this.showSpinner = true;

            if (platform === "twitter") {
                this.tweetURL = `https://smoothri.de/view/${submission.submission_id}`;
             
                try {
                const response = await axios.post(`${process.env.VUE_APP_SERVER_URL}/create-tweet`, {
                    prompt: this.reportText + ' - Output the tweet in ' + this.language + ' language!',
                });
                const { record_id, tweet } = response.data;
                console.log(`Tweet submission created with ID: ${record_id}`);
                console.log(`Tweet content: ${tweet}`);
                this.reportText = tweet +' ' + this.tweetURL;
                this.reportGenerated = true;
                } catch (error) {
                console.error("Failed to create tweet submission:", error);
                this.reportStatus = "Failed to create tweet submission.";
                }
            } 

            this.showSpinner = false;
            },
            postToTwitter() {
                const twitterShareURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
                    this.reportText
                )}`;
                window.open(twitterShareURL, "_blank");
            },

    copyToClipboard() {
        const textarea = this.$refs.reportTextarea;
        textarea.select();
  
        navigator.clipboard
          .writeText(textarea.value)
          .then(() => {
            // Copy successful
            console.log("Text copied to clipboard");
            this.reportStatus = "Report copied to clipboard.";
          })
          .catch((error) => {
            // Copy failed
            console.error("Failed to copy text to clipboard:", error);
            this.reportStatus = "Failed to copy report to clipboard.";
          });
      },

      copyLinkToClipboard() {
      navigator.clipboard
        .writeText(window.location.href)
        .then(() => {
          // Copy successful
          console.log("Link copied to clipboard");
          this.reportStatus = "Link copied to clipboard.";
        })
        .catch((error) => {
          // Copy failed
          console.error("Failed to copy link to clipboard:", error);
          this.reportStatus = "Failed to copy link to clipboard.";
        });
    },

    }
  };
  </script>
  

  <style scoped>


.language-select-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.language-select {
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 12px;
  border: 1px solid #ccc;
  background-color: #fff;
  outline: none;
  transition: border-color 0.3s;
  width: 200px;
}

.language-select:focus {
  border-color: var(--primary-color)
}
.share-report {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
    gap: 20px;
    flex-direction: column;
}


</style>

  