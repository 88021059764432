import { createRouter, createWebHistory } from 'vue-router'
import ExploreFeed from '@/views/ExploreFeed.vue'
// import SubmitReport from './views/SubmitReport.vue'
import SubmissionForm from '@/views/SubmissionForm.vue'
import UserDashboard from '@/views/UserDashboard.vue'
import ViewSubmission from '@/views/ViewSubmission.vue'
import ProjectInfo from '@/views/ProjectInfo.vue'
import SettingsScreen from '@/views/SettingsScreen.vue'
import LandingPage from '@/views/LandingPage.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
        path: '/',
        name: 'LandingPage',
        component: LandingPage,
        meta: {
          title: 'SmoothRide',
          description: 'Share, Learn, Improve - SmoothRide is a service for reporting road conditions and seeking actionable advice from AI to improve the actions local governments take to improve road conditions.',
        }
        },
    {
      path: '/explore',
      name: 'Explore',
      component: ExploreFeed,
      meta: {
        title: 'Explore | SmoothRide',
        description: 'Submit a report on SmoothRide GPT',
        // any other metadata you want to add
      }
    },
    {
      path: '/submit',
      name: 'Submit',
      component: SubmissionForm,
      meta: {
        title: 'Submit | SmoothRide',
        description: 'Submit a report on SmoothRide GPT',
        // any other metadata you want to add
      }
    },
    {
      path: '/profile',
      name: 'Profile',
      component: UserDashboard,
      meta: {
        title: 'Profile | SmoothRide',
        description: 'View your profile on SmoothRide GPT',
        // any other metadata you want to add
      }
    },
    {
      path: '/settings',
      name: 'Settings',
      component: SettingsScreen
    },
    {
      path: '/survey',
      name: 'Survey',
      beforeEnter() { 
        // using location.href to force a full page reload
        location.href = 'https://quant-ux.com/#/test.html?h=a2aa10aK4b30fYuGMoc1yGdL4OyRekszMqI26zAhVhGfMFIMZMV2w9KNJ7y2&ln=en'
      }
    },    
    {
      path: '/about',
      name: 'About',
      component: ProjectInfo
    },
    // other routes
    {
      path: '/view/:id',
      name: 'ViewSubmission',
      component: ViewSubmission,
      meta: {
        title: 'SmoothRide - View',
        description: 'View details of a submission on SmoothRide GPT',
        // any other metadata you want to add
      }
    }, 

  
  ], 
  devtools: true, // enable Vue Router inspector
})

export default router
