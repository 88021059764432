<template>
  <nav class="bottom-nav" :class="{ 'dark-mode': isLandingPage }">
    <router-link to="/explore" class="bottom-nav-item" active-class="active">
      <i class="fas fa-map"></i>
      <span class="bottom-nav-label">Explore</span>
    </router-link>
    <router-link to="/submit" class="bottom-nav-item" active-class="active">
      <i class="fas fa-camera"></i>
      <span class="bottom-nav-label">Submit</span>
    </router-link>
    <router-link to="/profile" class="bottom-nav-item" active-class="active">
      <i class="fas fa-user"></i>
      <span class="bottom-nav-label">Profile</span>
    </router-link>
  </nav>
</template>

<style scoped>
/* @keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */

.bottom-nav {
  display: flex;
  gap: 24px;
  justify-content: space-around;
  align-items: center;
  background-color: var(--color-background-light);
  position: fixed;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%) scale(0.9);
  padding: 16px 20px;
  z-index: 100;
  box-shadow: 0 0px 40px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  /* animation: fade-in .5s ease-in; */
}

.bottom-nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: var(--color-secondary-dark);
  font-size: 14px;
  position: relative;
  padding: 10px;
  transition: transform 0.3s ease-out;
  transform-origin: center center;
}


.bottom-nav-item.active {
  font-weight: bold;
  background: var(--color-primary-transparent);
  border-radius: 1rem;
  color: var(--color-primary);
  transform: scale(1.1);
}

.bottom-nav-item.active .bottom-nav-label {
  color: var(--color-secondary-dark);
}
.bottom-nav-item i {
  margin-bottom: 5px;
  font-size: 24px;
}

.bottom-nav-label {
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: color 0.3s ease-out;
}

.bottom-nav-item:hover {
  transform: scale(1.2);
}

.bottom-nav-item:active {
  transform: scale(1.1);
}


.dark-mode .bottom-nav  {
  background-color: var(--color-background-dark);
  color: var(--color-text-light);
  box-shadow: 0 0 40px rgba(255, 255, 255, 0.1);
}

.dark-mode.bottom-nav  {
  background-color: var(--color-background-dark);
  color: var(--color-text-light);
  box-shadow: 0 0 40px rgba(255, 255, 255, 0.1);
}


.dark-mode .bottom-nav-item {
  color: var(--color-text-light);
}
.dark-mode .bottom-nav-item.active .bottom-nav-label {
  color: var(--color-text-light);
}


@media screen and (max-width: 600px) {
  .bottom-nav {
    font-size: 12px;
    gap: 16px;
    bottom: 4px;
    height: 64px;
    left: 50%;
    transform: translateX(-64%);
    scale: .8;
  }
}
</style>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export default {
  setup() {
    const route = useRoute();
    const isLandingPage = computed(() => route.path === '/');
    
    return {
      isLandingPage,
    };
  },
};

</script>
