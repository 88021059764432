<template>
    <div class="project-info">
      <div class="container ">
        <div class="table-of-contents">
          <!-- <h3>Table of Contents</h3> -->
          <ul>
            <li v-for="record in records" :key="record.id" :class="{ active: isActive(record) }">
              <a :href="`#${record.slug}`">{{ record.title }}</a>
            </li>
          </ul>
        </div>
        <div v-for="record in records" :key="record.id">
          <div class="content fade-in">
            <h2 :id="record.slug" v-underline-primary>{{ record.title }}</h2>
            <div v-html="record.content"></div>
          </div>
        </div>
      </div> 
    </div>
  </template>
  
  <script>
  export default {
    name: 'ProjectInfo',
    data() {
      return {
        records: [],
        activeSection: null,
      };
    },
    async created() {
      try {
        const data = await this.$pb.collection('posts').getList(1, 50, {
          sort: '-updated',
        });
        this.records = data.items;
        window.addEventListener('scroll', this.onScroll);
      } catch (error) {
        console.error(error);
      }
    },
    beforeUnmount() {
      window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
      isActive(record) {
        return this.activeSection === record.slug;
      },
      onScroll() {
        const sections = document.querySelectorAll('.content > h2');
        const currentSection = Array.from(sections).find(
          (section) => section.getBoundingClientRect().top <= 100
        );
        if (currentSection) {
          this.activeSection = currentSection.id;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .table-of-contents {
    position: fixed;
    top: 36px;
    right: 20px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    /* border: 1px solid #898989; */
    background-color: var(--color-not-just-white);
    padding: 8px;
    border-radius: 12px;
    max-width: 400px;
    overflow-x: scroll;
    white-space: nowrap;
    z-index: 100;
  }
  .table-of-contents a {
    color: var(--color-text);
    text-decoration: underline;
  }
  .table-of-contents h3 {
    font-size: 1rem;
    margin: 0 0 10px;
  }
  .table-of-contents ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .table-of-contents li {
    margin: 20px 12px;
    text-decoration: underline;
    text-align: left;
}
  .table-of-contents a {
    text-decoration: none;
  }
  .table-of-contents .active {
    font-weight: bold;
  }
  @media (max-width: 768px) {
    .project-info {
      padding-bottom: 200px;}
    .table-of-contents {
      top: auto;
      bottom: 120px;
    }
  }
  </style>
  