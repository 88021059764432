<!-- CategoryRating.vue -->
<template>
  <div class="category-rating fade-in">
    <h3>{{ category }}</h3>
    <p>{{ description }}</p>
    <StarRating v-model="rating" :uniqueID="`${category.toLowerCase()}-${id}`"/>
  </div>
</template>

<script>
import StarRating from './StarRating.vue';

export default {
  name: 'CategoryRating',
  components: {
    StarRating,
  },
  props: {
    category: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
  },
  methods: {
    rate() {
      // if StarRating is clicked, emit the rate event

      this.$emit('rate', this.rating);
      print('rating');
      // this.$emit('rate');
    },

  },
  computed: {
  
  },
};
</script>

<style scoped>
/* Styles for the category-rating component */
</style>
