import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});

export default createStore({
  state: {
    darkModeEnabled: localStorage.getItem('darkModeEnabled') === 'true' || false,
    user: null,
  },
  mutations: {
    setDarkModeEnabled(state, payload) {
      state.darkModeEnabled = payload;
      localStorage.setItem('darkModeEnabled', payload);
      document.querySelector('body').classList.toggle('dark-mode', state.darkModeEnabled);
    },
    initializeDarkMode(state) {
      state.darkModeEnabled = localStorage.getItem('darkModeEnabled') === 'true';
      document.querySelector('body').classList.toggle('dark-mode', state.darkModeEnabled);
    },
    setUser(state, user) {
      state.user = user;
    },
    clearUser(state) {
      state.user = null;
    },
  },
  plugins: [vuexLocal.plugin],
  // add listener to save Vuex state to localStorage before page is unloaded
  actions: {
    saveState({ state }) {
      localStorage.setItem('vuexState', JSON.stringify(state));
    },
    async login() {
      const pb = this._vm.$pb; // access the PocketBase instance from the Vue app instance
      const userCollection = pb.collection('users');
    
      try {
        const authData = await userCollection.authWithPassword(this.username, this.password);
        if (!authData || !authData.token || !authData.model || !authData.model.id) {
          throw new Error('Invalid authentication data');
        }
    
        // Store the user data in Vuex
        this.loginUser({ token: authData.token, userId: authData.model.id });
    
        // Reload the current route to refresh the user data
        this.$router.go();

      } catch (error) {
        console.error(error);
      }
    },
    async updateUser({ commit, state }) {
      const pb = this._vm.$pb; // access the PocketBase instance from the Vue app instance
      const userCollection = pb.collection('users');
      
      // Fetch the user record using the ID from the Vuex state
      const resultList = await userCollection.getList(1, 1, {
        filter: `id == '${state.user.id}'`
      });
      
      if (resultList.items.length > 0) {
        const user = resultList.items[0];
        
        // Update the user state in Vuex
        commit('setUser', user);
        
        // Save the updated user state to local storage
        localStorage.setItem('user', JSON.stringify(user));
      }
    },
  
    logout({ commit }, { $pb }) {
      commit('clearUser');
      $pb.authStore.clear();
      window.localStorage.removeItem('pocketbase_auth');
    }
          
  },
  created() {
    this.commit('initializeDarkMode');
    window.addEventListener('beforeunload', this.actions.saveState);
  },  
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.actions.saveState);
  }
});
