<!-- MapPicker.vue  -->
<template>
    <div class="map-picker-wrapper" ref="mapPicker">
      <ol-map :zoomToMousePosition="false" id="test" style="height:250px; width:100%;    border-radius: 12px; overflow: hidden;">
        <ol-view style="border-radius: 12px;"
          ref="view"
          :center="center"
          :rotation="rotation"
          :zoom="zoom"
          :projection="projection"
          @zoomChanged="zoomChanged"
          @centerChanged="centerChanged"
          @resolutionChanged="resolutionChanged"
          @rotationChanged="rotationChanged"
        />

        <ol-tile-layer>
          <ol-source-osm />
        </ol-tile-layer>
  
    </ol-map>
  
    
    <ol-control class="btn-round absolute transparent" :style="{bottom: '32px', right: '35px', 'z-index': '10'}" @click="getCurrentLocation">
      <i class="fas fa-crosshairs"></i>
    </ol-control>
    
    <ol-control class="btn-cta absolute transparent" :position="{bottom: '40px', left: '50%'}" @click="handleLocationSelected">
        <i class="fas fa-check"></i>
    </ol-control>

    <svg class="absolute no-interaction" viewBox="0 0 100 100" style="width: 70px; height: 70px;">
      <circle cx="50" cy="50" r="20" stroke="var(--color-primary)" stroke-width="2" fill="none" />
      <line x1="0" y1="50" x2="100" y2="50" stroke="var(--color-primary)" stroke-width="2" />
      <line x1="50" y1="0" x2="50" y2="100" stroke="var(--color-primary)" stroke-width="2" />
    </svg>
    </div>
  </template>
  
  <script>
  import { ref } from "vue";
  
  export default {
    props: {
    defaultLat: {
      type: Number,
      default: 40,
    },
    defaultLng: {
      type: Number,
      default: 40,
    },
    defaultZoom: {
      type: Number,
      default: 8,
    },
    defaultRotation: {
      type: Number,
      default: 0,
    },
  },

  setup(props) {
    const center = ref([props.defaultLng, props.defaultLat]);
    const projection = ref("EPSG:4326");
    const zoom = ref(props.defaultZoom);
    const rotation = ref(props.defaultRotation);

    return {
      center,
      projection,
      zoom,
      rotation,
    };
  },
  
    data() {
      return {
        currentCenter: this.center,
        currentZoom: this.zoom,
        currentResolution: this.resolution,
        currentRotation: this.rotation,
      };
    },
  
    methods: {
      zoomChanged(currentZoom) {
        this.currentZoom = currentZoom;
      },
  
      resolutionChanged(resolution) {
        this.currentResolution = resolution;
      },
  
      centerChanged(center) {
        this.currentCenter = center;
      },
  
      rotationChanged(rotation) {
        this.currentRotation = rotation;
      },

      handleLocationSelected() {
        const center = this.currentCenter;
        console.log(center);
        this.$emit("location-selected", {lat: center[1], lng: center[0]});
      },
      getCurrentLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            this.center = [longitude, latitude];
            this.zoom = 16;
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    },


      
    },
  };
  </script>
  
  <style scoped>
  

  ol-map {
    border-radius: 12px;
    overflow: hidden;

  }
  .map-picker-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin-bottom: -50px;
    gap: 12px;
    transform: translate(0px, -100px);
    z-index: 1;
  }

.absolute {
    position: absolute;

}
.no-interaction {
    pointer-events: none;

}
.transparent {
    opacity: .8;
}
  .map-info {
    margin-top: 10px;
  }


  ol-control.btn-cta.absolute {
    transform: translate(0px, 120px);
}
  </style>
  