<template>
  <div v-if="cyclingConditions" class="success-metric fade-in cycling-conditions" :style="backgroundStyle">
    <h2>Cycling Experience in 📍<span style="font-weight: bold;">{{ cyclingConditions.location }}</span></h2>
    <p>🌇 Municipalities, time to learn from each other: German cycling club ADFC conducted an extensive survey on cycling experiences throughout the country, allowing participants to rate their experiences on a scale of 1 (Best) to 6 (Worst). Does your submission pertain to any of the mentioned categories?</p>
    <p>
      <i class="fas fa-medal"></i>
      Ranking:
      <span class="rating-value">{{ cyclingConditions['Rank'] }} (Germany)</span>
    </p>
    <p>
      <i class="fas fa-star"></i>
      Overall Rating:
      <span class="rating-value" :class="getRatingClass(cyclingConditions['Overall Rating'])">
        {{ cyclingConditions['Overall Rating'] }}
      </span>
    </p>
    <p>
      <i class="fas fa-shield-alt"></i>
      Safety in Cycling:
      <span class="rating-value" :class="getRatingClass(cyclingConditions['Safety_in_Cycling'])">
        {{ cyclingConditions['Safety_in_Cycling'] }}
      </span>
    </p>
    <p>
      <i class="fas fa-smile"></i>
      Comfort in Cycling:
      <span class="rating-value" :class="getRatingClass(cyclingConditions['Comfort_in_Cycling'])">
        {{ cyclingConditions['Comfort_in_Cycling'] }}
      </span>
    </p>

    <!-- more details to unfold -->
    <button class="btn-round" @click="toggleCyclingConditions">
      <i class="fas fa-chevron-down"></i>
    </button>

    <!-- more details if showCyclingConditions -->
    <div class="rating-container" v-if="showCyclingConditions">
      <div class="fade-in rating" v-for="(value, key) in filteredCyclingConditions" :key="key">
        {{ key }}:

        <div class="rating-value-box">
          <span class="rating-value" :class="getRatingClass(value)">{{ value }}</span>
          <span v-if="cyclingConditions['BIC_' + key]" class="rating-value" :class="getRatingClass(parseFloat(cyclingConditions['BIC_' + key].match(/\((.*?),/)[1]))">
            {{ cyclingConditions['BIC_' + key] }}
          </span>
        </div>
      </div>
    </div>

    <!-- Add more properties as needed -->
    <label>Data: <a target="_blank" href="https://fahrradklima-test.adfc.de/ergebnisse">ADFC Fahrradklimatest 2022</a></label>
  </div>
</template>

<script>
export default {
  name: "CyclingConditions",
  props: {
    city: {
      type: String,
      required: true,
    },
    cyclingConditions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showCyclingConditions: false,
    };
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: `linear-gradient(rgba(65, 196, 171, 0.5), rgba(65, 196, 171, 0.8) 40%, rgb(65, 196, 171)), url("/urbancyclist_fade.jpg")`,
      };
    },
    filteredCyclingConditions() {
      // Create a new object where keys starting with 'BIC_' are excluded
      return Object.keys(this.cyclingConditions).reduce((result, key) => {
        if (!key.startsWith('BIC_')) {
          result[key] = this.cyclingConditions[key];
        }
        return result;
      }, {});
    },
  },
  methods: {
    toggleCyclingConditions() {
      this.showCyclingConditions = !this.showCyclingConditions;
    },
    getRatingClass(rating) {
      if (rating <= 1.0) {
        return 'excellent';
      } else if (rating <= 2.0) {
        return 'good';
      } else if (rating <= 3.0) {
        return 'average';
      } else if (rating <= 4.0) {
        return 'poor';
      } else {
        return 'bad';
      }
    },
  },
};
</script>

<style scoped>

h2 {
  color: var(--color-text-light);
}
/* Style for the cycling conditions section */
.cycling-conditions {
  /* Background image */
  background-repeat: no-repeat;
  background-size: auto;
  background-position: top;
}

.rating {
  display: flex;
  justify-content: space-between;
}

.rating-value {
  display: inline-block;
  padding: 2px 5px;
  border-radius: 4px;
  color: #ffffff;
  font-weight: bold;
}

.rating-value-box {
  display: flex;
  gap: 8px;
}

.rating-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
}

.rating-value.excellent {
  background-color: #00cc66;
}

.rating-value.good {
  background-color: #66cc00;
}

.rating-value.average {
  background-color: #cccc00;
}

.rating-value.poor {
  background-color: #cc6600;
}

.rating-value.bad {
  background-color: #cc0000;
}


/* Mobile */
@media (max-width: 768px) {
  .rating {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      gap: 8px;
    }

}
</style>
