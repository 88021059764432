<template>
  <div class="star-rating">
    <span v-for="i in 5" :key="i" @click="setRating(i)" :class="{ active: i <= rating }">
      <i class="fas fa-star"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: 'StarRating',
  props: {
    initialRating: {
      type: Number,
      default: 0
    },
    uniqueID: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      ratings: this.getRatings()
    }
  },
  computed: {
    rating() {
      return this.ratings[this.uniqueID] || this.initialRating;
    }
  },
  methods: {
    setRating(rating) {
      const existingRatings = this.getRatings();
      const updatedRatings = {
        ...existingRatings,
        [this.uniqueID]: rating
      };
      this.ratings = updatedRatings;
      localStorage.setItem('ratings', JSON.stringify(updatedRatings));
      // print uniqueID and rating to console
      console.log(`uniqueID: ${this.uniqueID}, rating: ${rating}`);
      // if not uniqueID  include video or advice, then update the rating in the database

      if (this.uniqueID.includes('video') || this.uniqueID.includes('advice')) {
        // skip since AdviceSection got their own handler
      }else{
        this.updateRatings();
      }    
      this.$emit('input', rating);
    },
    getRatings() {
      const storedRatings = localStorage.getItem('ratings');
      return storedRatings ? JSON.parse(storedRatings) : {};
    },
    // upload rating to database 
    // first get from the uniqueID the category and the id
    // then update the rating in the database

    async updateRatings() {
      const category = this.uniqueID.split('-')[0];
      const id = this.uniqueID.split('-')[1];
      const categoryKey = 'rating_' + category;
      const categoryAmountKey = 'rating_' + category + '_amount';

      try {
        const record = await this.$pb.collection('reports').getOne(id);
        if (record) {
          const categoryValue = record[categoryKey] || 0;
          const categoryAmountValue = record[categoryAmountKey] || 0;

          const updatedRecord = {
            ...record,
            [categoryKey]: categoryValue + this.rating,
            [categoryAmountKey]: categoryAmountValue + 1,
          };

          await this.$pb.collection('reports').update(id, updatedRecord);
        }
      } catch (error) {
        console.error(`Error updating rating in reports record for id ${id}:`, error);
      }
    },


  }
}
</script>

<style scoped>
.star-rating {
  display: inline-flex;
  font-size: 20px;
}

.star-rating span {
  cursor: pointer;
  margin-right: 5px;
  color: #ccc;
}

.star-rating span.active {
  color: #FFC107;
}
</style>
