<template>
  <div class="auth-form content fade-in">
    <h1>{{ isLogin ? "Login" : "Register" }}</h1>

    <div class="social" id="socialbuttons"></div>

    <hr style="width: 80px" />

    <form @submit.prevent="submitForm">
      <label>
        {{ isLogin ? "Username or email" : "Your email" }}
        <input type="text" v-model="username" />
      </label>
      <label>
        Password:
        <input type="password" v-model="password" />
      </label>
      <label v-if="!isLogin">
        Confirm Password:
        <input type="password" v-model="confirmPassword" />
      </label>

      <label v-if="!isLogin"
        >Choose a nickname
        <div class="nickname-input-container">
          <input type="text" v-model="nickname" />
          <button
            class="btn-round"
            type="button"
            @click="nickname = generateNickname()"
          >
            🎲
          </button>
        </div>
      </label>

      <button class="btn-cta" type="submit">
        {{ isLogin ? "Login" : "Register" }}
      </button>
    </form>

    <p>
      {{ isLogin ? "Need an account?" : "Already have an account?" }}
      <a href="#" @click="toggleForm">{{ isLogin ? "Register" : "Login" }}</a>
    </p>
  </div>
</template>

<script>
import $ from "jquery";
// const dotenv = require('dotenv');

export default {
  data() {
    return {
      isLogin: true,
      authProviders: [],
      username: "",
      nickname: "",
      password: "",
      confirmPassword: "",
    };
  },
  created() {
    this.loadProviders();
  },
  methods: {
    async loadProviders() {
      const authMethods = await this.$pb.collection("users").listAuthMethods();
      const buttons = [];
      const redirectPath = "/profile";
      const redirectUrl = `${process.env.VUE_APP_URL}${redirectPath}`;

      for (const provider of authMethods.authProviders) {
        const $button = $(`<button class="btn-standard"></button>`);

        $button.attr("title", `Login with ${provider.name}`).click(function () {
          // Store provider's data on click for verification in the redirect page
          localStorage.setItem("provider", JSON.stringify(provider));
          const authUrl = provider.authUrl + redirectUrl;
          window.location.href = authUrl;
        });

        $button.html(
          `<i class="fab fa-${provider.name}"></i> Login with ${
            provider.name.charAt(0).toUpperCase() + provider.name.slice(1)
          }`,
        );
        buttons.push($button);
      }

      $("#socialbuttons").html(
        buttons.length ? buttons : "<li>No OAuth2 providers.</li>",
      );
    },

    toggleForm() {
      this.isLogin = !this.isLogin;
      this.username = "";
      this.password = "";
      this.confirmPassword = "";
    },

    openUrlInNewTab(url) {
      const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        );
      if (isMobile) {
        window.location.href = url;
      } else {
        window.open(url, "_blank", "");
      }
    },

    generateNickname() {
      const adjectives = [
        "spandex",
        "lycraclad",
        "urban",
        "clumsy",
        "rusty",
        "hipster",
        "tandem",
        "cruiser",
        "schmetterling",
        "flamingo",
        "eichhoernchen",
        "knallbonbon",
        "cargo",
        "lasten",
        "doctor",
        "suv",
        "funky",
        "radical",
        "rebel",
        "green",
        "blue",
        "red",
        "yellow",
      ];
      const nouns = [
        "peddler",
        "roadie",
        "hopper",
        "chaser",
        "crasher",
        "wheeler",
        "spinner",
        "glider",
        "pacer",
        "radler",
        "raser",
        "koenig",
        "pirat",
        "fahrer",
        "schrauber",
        "flitzer",
        "blitz",
        "schwalbe",
        "hauler",
        "schlepper",
        "dreamer",
        "rebel",
        "warrior",
        "activist",
        "cyclepreneur",
        "cargopreneur",
        "climatecourier",
        "lastenradler",
        "sprinter",
        "ecowarrior",
        "veloflaneur",
      ];
      const randomNumber = Math.floor(Math.random() * 10000);
      const adjective =
        adjectives[Math.floor(Math.random() * adjectives.length)];
      const noun = nouns[Math.floor(Math.random() * nouns.length)];
      const nickname = `${adjective}${noun}${randomNumber
        .toString()
        .padStart(4, "0")}`;
      return nickname.toLowerCase();
    },

    async submitForm() {
      const userCollection = this.$pb.collection("users");
      try {
        if (this.isLogin) {
          const authData = await userCollection.authWithPassword(
            this.username,
            this.password,
          );
          const userId = authData.model ? authData.model.id : null;
          this.$store.dispatch("loginUser", { token: authData.token, userId });
        } else {
          if (this.password !== this.confirmPassword) {
            throw new Error("Passwords do not match");
          }

          const data = {
            username: this.nickname,
            email: this.username,
            password: this.password,
            passwordConfirm: this.confirmPassword,
            name: this.name,
            points: 100,
            user_challenges: ["6xu57yodnwkhrmd"],
          };

          const record = await userCollection.create(data);
          console.log(record);
          await this.$pb.collection("users").requestVerification(this.username);
          alert(
            `Thank you for registering. Please check your email (${this.username}) to verify your account.`,
          );
        }
        window.location.reload();
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.nickname-input-container {
  display: flex;
}

.social {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.auth-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: auto;
  margin-bottom: 64px;
}

.auth-form h2 {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 28px;
  font-weight: 300;
  text-align: center;
}

.auth-form label {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
}

.auth-form input[type="text"],
.auth-form input[type="password"] {
  padding: 12px;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 18px;
}

.auth-form input[type="text"]:focus,
.auth-form input[type="password"]:focus {
  outline: none;
  border-bottom: 1px solid var(--color-primary);
}
</style>
