<!-- FooterSection.vue -->
<template>
    <footer class="footer">
    <div class="social-media-icons">
      <a href="https://twitter.com/InfrastructureGPT"><i class="fab fa-twitter"></i></a>
      <a href="https://www.linkedin.com/company/InfrastructureGPT"><i class="fab fa-linkedin"></i></a>
      <a href="https://github.com/InfrastructureGPT"><i class="fab fa-github"></i></a>
    </div>
    <DarkModeToggle v-model="darkMode">
      <i :class="['fas', darkMode ? 'fa-moon' : 'fa-sun']"></i>
    </DarkModeToggle>
  </footer>
  <p class="copyright">
      &copy; {{ new Date().getFullYear() }} SmoothRide / Research Preview 
    </p>

</template>

<script>
import DarkModeToggle from '@/components/DarkModeToggle.vue'; // import any other components needed

export default {
  name: 'FooterSection',
  components: {
    DarkModeToggle,
  },
  data() {
    return {
      darkMode: false,
    };
  },
};
</script>

<style scoped>
 .footer {
display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #f7f7f7; */
    padding: 20px;
    color: var(--color-text);
    /* position: fixed; */
    bottom: 0;
    /* width: 100%; */
    gap: 20px;
    
  }
  .dark-mode .footer, .dark-mode .footer  a{
  /* color: var(--color-secondary-light); */
  color: var(--color-text-light);
}


  .social-media-icons {
    display: flex;
    gap: 20px;
  }

  .social-media-icons a:hover {
    color: var(--color-primary);
  }

  .toggle-switch {
    margin-right: 20px;
  }
  .social-media-icons a {
    color: var(--color-secondary-dark);
}

  
  </style>
