<!-- SuccessScreen.vue -->
<template>
  <div class="success-screen content">
    <div class="success-icon">
      <i class="fas fa-check fa-3x"></i>
    </div>
      <h2 v-underline-primary>Report submitted successfully!</h2>
      <p>Thank you for your contribution to making our city a better place for cycling.</p>
      <router-link to="/explore">
        <button class="btn-cta"><i class="fas fa-map"></i> Explore submissions </button>
      </router-link>
        <AdviceSection
            ref="recsSection"
            v-if="contentRecs"
            :h2="'Content Recommendations'"
            :subtitle="'The provided content recommendations are videos related to the topic and meant to share best practices and inspire you to take action. You can rate the advice or add your own suggestion so city officials can learn from your experience.'"
            :result="contentRecs"
            :ratings="ratings"
            @update-rating="updateRating" 
            @select-sticky-note="selectedStickyNote = $event"
        />
    </div>
  </template>
  
  <script>
  import AdviceSection from "./AdviceSection.vue";
  
  export default {
    name: "SuccessScreen",
    components: {
      AdviceSection,
    },
    props: {
      infrastructureAdvice: {
        type: Array,
        required: true
      },
      contentRecs: {
        type: Array,
        required: true
      }
    },

  };
  </script>
  
  <style>

.success-screen {
  display: flex;
  flex-direction: column;
  animation: fade-in 1s ease-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
  .recommendations {
    margin-top: 30px;
  }

  .success-icon {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.success-icon i {
  color: #fff;
  background-color: var(--color-primary);
  border-radius: 50%;
  padding: 1rem;
  animation: spin 1s linear 1;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

  </style>
  