<template>
  <div class="landing-page fade-in">
    <!-- <img class="landing-graphic" src="landing_head.svg" alt="SmoothRide Landing"> -->

    <h1 class="fade-in">
      <span class="headline">“Your </span>
      <span class="headline-bold">AI</span>
      <span class="headline"
        >dvisor for future-proof infrastructure & more livable places”</span
      >
    </h1>
    <a
      href="https://www.producthunt.com/posts/smoothride-infrastructuregpt?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-smoothride&#0045;infrastructuregpt"
      target="_blank"
      ><img
        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=401591&theme=dark"
        alt="SmoothRide&#0032;&#0047;&#0032;InfrastructureGPT - Citizen&#0032;x&#0032;AI&#0058;&#0032;Together&#0032;towards&#0032;better&#0032;infrastructure | Product Hunt"
        style="width: 200px; height: 54px"
        width="200"
        height="54"
    /></a>
    <!-- <div style="width: 526px; text-align: center"><span style="color: #1A1A1A; font-size: 35.05px; font-family: Poppins; font-weight: 250; word-wrap: break-word">“Your </span><span style="color: #1A1A1A; font-size: 35.05px; font-family: Poppins; font-weight: 500; word-wrap: break-word">AI</span><span style="color: #1A1A1A; font-size: 35.05px; font-family: Poppins; font-weight: 250; word-wrap: break-word">dvisor for future-proof infrastructure & more livable places”</span></div> -->

    <div class="btn-wrapper">
      <router-link to="/about">
        <button class="btn-round">
          <i class="fas fa-info"></i>
        </button>
      </router-link>
      <button class="btn-cta" @click="scrollToBottom">
        <i class="fas fa-bicycle"></i> Get started
      </button>
    </div>

    <h2 class="fade-in">
      <span class="headline-bold">share </span>
      <span class="headline">your experiences</span>
      <span class="headline-bold"> 🚲</span>
    </h2>
    <img
      class="landing-graphic"
      src="landing_content_top.svg"
      alt="SmoothRide Landing"
    />
    <div class="btn-wrapper">
      <router-link to="/explore">
        <button class="btn-round"><i class="fas fa-map"></i></button>
      </router-link>
      <router-link to="/submit">
        <button class="btn-cta">
          <i class="fas fa-camera"></i> Submit report
        </button>
      </router-link>
    </div>
    <h2 class="fade-in">
      <span class="headline-bold">ideate </span>
      <span class="headline">on the situation</span>
      <span class="headline-bold"> 🧑🏽🤝🤖</span>
    </h2>
    <AdviceSection
      :h2="'Action-advice by OpenAI 🤖'"
      :result="advice_array"
      @update:result="result = $event"
    />

    <h2 class="fade-in">
      <span class="headline-bold">be inspired </span>
      <span class="headline">by curated content 📼</span>
    </h2>

    <AdviceSection
      :h2="'Content recommendations'"
      :result="content_array"
      @update:result="result = $event"
    />

    <div class="btn-wrapper">
      <router-link to="/submit">
        <button class="btn-round"><i class="fas fa-camera"></i></button>
      </router-link>
      <router-link to="/explore">
        <button class="btn-cta"><i class="fas fa-map"></i> Explore more</button>
      </router-link>
    </div>

    <!-- Section: Community -->
    <h2 class="fade-in">
      <span class="headline-bold">share & help </span>
      <span class="headline">the community</span>
      <span class="headline-bold"> 🤲🚴🏽‍♀️</span>
    </h2>
    <!--  -->
    <ul class="custom-list">
      <li>Raise awareness &amp; grow empathy towards people on bicycles</li>
      <li>
        Make issues more visible, discoverable and backed up by best practices
      </li>
      <li>
        Allow municipalities and other citizens to reimagine &amp; the places we
        live
      </li>
    </ul>

    <img
      class="landing-graphic"
      src="landing_content_loop.svg"
      alt="SmoothRide Landing"
    />
    <h2 class="fade-in">
      <span class="headline-bold">start </span>
      <span class="headline">today 🚴🏽‍♀️🚀</span>
    </h2>
    <div class="btn-wrapper">
      <router-link to="/explore">
        <button class="btn-round"><i class="fas fa-map"></i></button>
      </router-link>
      <router-link to="/submit">
        <button class="btn-cta">
          <i class="fas fa-camera"></i> Submit report
        </button>
      </router-link>
    </div>
    <img
      class="landing-graphic"
      src="landing_content_bottom.svg"
      alt="SmoothRide Landing"
    />
  </div>
</template>

<script>
import AdviceSection from "@/components/AdviceSection.vue";
export default {
  name: "LandingPage",
  components: {
    AdviceSection,
  },
  methods: {
    scrollToBottom() {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    },
  },
  data() {
    return {
      advice_array: [
        {
          advice:
            "Create a curb extension by temporarily adding a barrier that directs the water to the section between the parked cars and the bike lane. Add a few temporary planters on the barrier and surround them with chairs for people to rest.",
          category: "Fast to achieve",
          record_id: "4xgdknnf3yyso3f",
        },
        {
          advice:
            "Install a curb cut or permeable pavement in the affected area allowing water to drain. Additionally, consider installing bike shelters to protect cyclists from inclement weather.",
          category: "Best practice",
          record_id: "39nh4rav24qneow",
        },
        {
          advice:
            "Install temporary trampolines in front of traffic lights to allow bicyclists to safely wait out the rain. The trampolines could also generate electricity for the city's grid.",
          category: "Super creative",
          record_id: "0isry4ossa006yc",
        },
      ],
      content_array: [
        {
          video_id: "z75fqfp37KI",
        },
        {
          video_id: "EHwCYnBxYWQ",
        },
        {
          video_id: "kgdyq6ol-OM",
        },
      ],
    };
  },
};
</script>

<style scoped>
.landing-page h1,
.landing-page h2 {
  max-width: 600px;
}
ul,
li {
  list-style: inside;
  text-align: center;
  padding: 0;
  margin: 0;
}

li {
  padding: 10px 0;
}

li:last-child {
  border-bottom: none;
}

/* Desktop styles */
@media (min-width: 1025px) {
  .headline {
    font-size: 36px;
    font-family: Poppins;
    font-weight: 250;
    word-wrap: break-word;
  }

  .headline-bold {
    font-size: 36px;
    font-family: Poppins;
    font-weight: 500;
    word-wrap: break-word;
  }
}
.landing-page {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  align-content: stretch;
  gap: 40px;
  /* max-width: 600px; */
  margin: auto 16px;
  /* height: 100vh; */
}

.landing-page img {
  /* width: 80%; */
  max-width: 600px;
  width: 100vw;
}

/* scale down the advice section */
.advice-section {
  max-width: 100vw;
  transform: scale(0.8);
  margin-top: -32px;
  margin-bottom: -64px;
}

.btn-wrapper {
  display: flex;
  gap: 8px;
  flex-direction: row;
}

/* Tablet styles */
@media (min-width: 601px) and (max-width: 1024px) {
  .headline {
    font-size: 32px;
    font-family: Poppins;
    word-wrap: break-word;
    font-weight: 250;
  }

  .headline-bold {
    font-size: 32px;
    font-family: Poppins;
    font-weight: 500;
    word-wrap: break-word;
  }
}

/* Mobile styles */
@media (max-width: 600px) {
  .headline {
    font-size: 24px;
    font-family: Poppins;
    font-weight: 250;
    word-wrap: break-word;
  }

  .headline-bold {
    font-size: 24px;
    font-family: Poppins;
    font-weight: 500;
    word-wrap: break-word;
  }
  .landing-page {
    gap: 32px;
  }
  .advice-section {
    margin-top: -120px;
    margin-bottom: -120px;
  }
}
</style>
