<!-- ImageUpload.vue -->
<!-- The ImageUpload component is responsible for handling image uploads and displaying a preview of the selected image. When an image is selected, the component uses the exifr library to extract EXIF data, such as the camera make and model, date taken, and GPS location. The component also uses the heic2any library to convert HEIC images to JPEG format for compatibility with more devices. After extracting EXIF data, the component uses the axios library to send the image to a server for object detection. The detected objects are then displayed as labels below the preview image. The component also supports dragging and dropping images onto the upload area, and emits events to notify the parent component of the selected file and any updated EXIF data and labels. The preview image is scaled to fit within a maximum height and width, and a blurred background is displayed behind the image. Overall, the ImageUpload component provides a user-friendly way for users to upload images and get information about the content of the image. -->


<template>
  <div class="image-upload-map-wrapper">
    <div class="file-picker-wrapper" ref="filePicker">
      <label for="file-picker" class="file-picker-label" @dragover.prevent @drop.prevent="handleFileDrop">
        <div class="preview-blur" :style="{ backgroundImage: 'url(' + previewUrl + ')', height: `${maxHeight}px`, backgroundPosition: 'center' }"></div>
        <div class="file-picker-icon">
          <i class="fas fa-camera"></i>
        </div>
        <div class="file-picker-text">
          <span>Choose a file</span>
          <span class="file-picker-subtext">or drag it here</span>
        </div>
      </label>
      <input type="file" id="file-picker" accept="image/*" class="file-picker" @change="handleFileChange">
      <div class="exif-info" v-if="fileSelected">
        <!-- <div v-if="exif.Make">📷 {{ exif.Make }} {{ exif.Model }}</div> -->
        <div v-if="exif.DateTimeOriginal">📆 {{ new Date(exif.DateTimeOriginal).toLocaleDateString() }} {{ new Date(exif.DateTimeOriginal).toLocaleTimeString() }}</div>
        <div v-if="exif.latitude && exif.longitude">📍 {{ exif.latitude }}, {{ exif.longitude }}</div>
        <div v-if="exif.country">🏞️ <span v-if="exif.city">{{ exif.city }},</span> <span v-if="exif.region">{{ exif.region }},</span> <span v-if="exif.country">{{ exif.country }}</span></div>

        <button class="btn-standard change-location" @click="toggleMapPicker">{{ showMapPicker ? 'Hide 🗺️' : 'Change 📍' }}</button>
      </div>
    </div>
    <MapPicker v-if="showMapPicker || !exif" :default-lat="defaultLat" :default-lng="defaultLng" :default-zoom="defaultZoom" :default-rotation="defaultRotation" @location-selected="handleLocationSelected" ref="mapPicker" />
</div>
</template>


<script>
import axios from 'axios';
import exifr from 'exifr';
import heic2any from 'heic2any';
import MapPicker from './MapPicker.vue';

export default {
  props: {
    initialExif: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    MapPicker,
  },
  data() {
  return {
    file: null,
    exif: { ...this.initialExif },
    previewUrl: null,
    detectedLabels: null,
    allLabels: null,
    image_file: null,
    labeled_image_file: null,
    labelsVisible: false,
    fileSelected: null,
    maxHeight: 300,
    showMapPicker: false
  }
},

  computed: {
    defaultLat() {
      if (this.exif?.latitude) {
        return this.exif.latitude;
      } else {
        return 51.165691; // Germany's latitude
      }
    },
    defaultLng() {
      if (this.exif?.longitude) {
        return this.exif.longitude;
      } else {
        return 10.451526; // Germany's longitude
      }
    },
    defaultZoom() {
      return 8;
    },
    defaultRotation() {
      return 0;
    },
  },
  watch: {
    exif: {
      handler() {
        // console.log('Exif data updated:', this.exif);
      },
      deep: true
    }
  },
  methods: {

    toggleMapPicker() {
      if (!this.showMapPicker) {
        this.$refs.filePicker.scrollIntoView({ behavior: 'smooth' });
      }
      this.showMapPicker = !this.showMapPicker;
    },

    async handleFile(file) {

      if (file.type === 'image/heic') {
        const jpegFile = await heic2any({
          blob: file,
          toType: 'image/jpeg'
        });
        this.exif = await this.getExifData(file);
        file = new File([jpegFile], `${file.name}.jpg`, { type: 'image/jpeg' });

        
      }
      
      this.fileSelected = true;
      this.$emit('update:fileSelected', this.fileSelected);


      const img = new Image();
      img.src = URL.createObjectURL(file);
      await new Promise((resolve) => img.onload = resolve);

      let width = img.width;
      let height = img.height;
      const aspectRatio = width / height;
      const maxHeight = 300;
      const maxWidth = maxHeight * aspectRatio;
      if (height > maxHeight) {
        height = maxHeight;
        width = height * aspectRatio;
      } else if (width > maxWidth) {
        width = maxWidth;
        height = width / aspectRatio;
      }

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);
      const labelHeight = height + 40;
      const labelWidth = width;
      const filePickerLabel = document.querySelector('.file-picker-label');
      filePickerLabel.style.height = `${labelHeight}px`;
      filePickerLabel.style.width = `${labelWidth}px`;

      this.previewUrl = canvas.toDataURL(file.type);
      
      if (!this.exif.latitude){
        this.exif = await this.getExifData(file);
      }
      if (this.exif === null || Object.keys(this.exif).length === 0) {
        this.exif = this.initialExif;
        this.toggleMapPicker();
      } else {  
        // console.log(this.exif);
        await this.updateLocationInfo(this.exif.latitude, this.exif.longitude);
      }

      await this.detectObjects(file);
      // Emit the 'file-selected' event and scroll to the desired element
      this.$emit('file-selected', file);
    },

    async handleFileChange(event) {
      const file = event.target.files[0];
      await this.handleFile(file);
      this.$emit('file-selected', file);
    },

    async handleFileDrop(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      await this.handleFile(file);
    },
    
    async updateLocationInfo(latitude, longitude) {
      try {
        // Call the /geocode API endpoint to get the city and country for the location
        const geocodeResponse = await axios.get(process.env.VUE_APP_SERVER_URL + '/geocode', {
          params: {
            location: `${latitude},${longitude}`
          }
        });
        
        // Update the exif object with the location information
        if (this.exif) {
          // this.exif.latitude = latitude;
          // this.exif.longitude = longitude;
          this.exif.city = geocodeResponse.data.city;
          this.exif.region = geocodeResponse.data.region;
          this.exif.country = geocodeResponse.data.country;
          this.exif.address = geocodeResponse.data.address;
          this.$emit('exif-updated', this.exif);
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getExifData(file) {
      try {
        const exif =  await exifr.parse(file);
        this.$emit('exif-updated', exif);
        return exif || null;
      } catch (error) {
        console.error(error);
      }
    },
    async detectObjects(file) {
      try {
        const formData = new FormData();
        formData.append('file', file);
        const response = await axios.post(process.env.VUE_APP_SERVER_URL + '/detect', formData);
        
        this.allLabels = response.data.labels;
        this.image_file = response.data.image_file;
        this.labeled_image_file = response.data.labeled_image_file;
        this.detectedLabels = JSON.parse(JSON.stringify(this.allLabels));
        this.$emit('detected-labels-updated', this.detectedLabels);
        this.$emit('labeled-image-file-updated', this.labeled_image_file);
        this.$emit('image-file-updated', this.image_file);
        this.labelsVisible = true;
        this.$emit('labels-updated', this.allLabels);
        // this.$refs.labels.scrollIntoView({ behavior: 'smooth' });

   
      } catch (error) {
        console.error(error);
      }
    },

    updateLabels(newLabels) {
      this.allLabels = newLabels;
      this.$emit('labels-updated', this.allLabels);

    },

    updateInitialLabels(newLabels) {
      this.initialLabels = newLabels;
    },


    async handleLocationSelected(location) {
      if (this.exif) {
        this.exif.latitude = location.lat;
        this.exif.longitude = location.lng;
        await this.updateLocationInfo(this.exif.latitude, this.exif.longitude);
        this.$emit("exif-updated", this.exif);
      } else {
        this.$emit("exif-updated", { latitude: location.lat, longitude: location.lng });
      }
      this.showMapPicker = false;
    }

  }
}


</script>


<style scoped>


.preview-blur {
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 10px;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  /* filter: blur(3px); */
  /* z-index: -1; */
  z-index: 1;
  background-position: center;
  background-size: cover;
  max-height: -webkit-fill-available;


}

.image-upload-map-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.exif-info {
    display: flex;
    flex-direction: column;
    max-width: 170px;
    gap: 12px;

  }

.file-picker-icon {
  font-size: 48px;
  color: var(--color-primary);
}

.file-picker-text {
  margin-left: 16px;
  font-size: 18px;
  text-align: center;
  color: var(--color-primary);
}

.file-picker-subtext {
  display: block;
  font-size: 14px;
  /* color: var(--color-text-dark); */
  margin-top: 8px;
}

.file-picker {
  display: none;
  /* background-color: var(--background-color-light); */
  background-color: white;

}

.file-picker-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 2;

  /* height: 100%; */
  /* max-height: 300px; */
  gap: 12px;
}

.file-picker-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  transition: height 0.2s ease-in-out;
  border-radius: 10px;
  border: 3px dashed #4dc6e126;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  position: relative;
  /* background-color: #F2F2F2; */
  
}

.file-picker-label {
  transition: all 0.2s ease-in-out;
}


.file-picker-label:hover {
  background-color: rgba(31, 117, 254, 0.1);
  /* transform: translateY(-4px);
  box-shadow: 0px 6px 0px var(--color-primary); */

}

</style>