<template>
  <div class="dark-mode-switch" :class="{ 'dark-mode-switch--checked': checked }" @click="toggle">
    <i class="fas fa-moon" v-if="!checked"></i>
    <i class="fas fa-sun" v-else></i>
  </div>
</template>

<script>
export default {
  name: 'DarkModeToggle',
  computed: {
    checked() {
      return this.$store.state.darkModeEnabled;
    }
  },
  methods: {
    toggle() {
      this.$store.commit('setDarkModeEnabled', !this.checked);
    }
  },
  beforeMount() {
    document.querySelector('body').classList.toggle('dark-mode', this.checked);
  }
}
</script>


<style scoped>
.dark-mode-switch {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  background-color: #555;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;

}

.dark-mode-switch--checked {
  background-color: var(--color-primary);
}

.dark-mode-switch i {
  color: #fff;
  font-size: 20px;
}

.dark-mode-switch i.fas.fa-sun {
  display: none;
}
.dark-mode-switch--checked i.fas.fa-sun {
  display: block;
}
.dark-mode-switch--checked i.fas.fa-moon {
  display: none;
}
</style>
