<template>
  <div class="severity-label">
    <!-- <p class="severity-label__label">Severity:</p> -->
    <span :class="[
      'severity-label__value',
      severity === 1 ? 'severity-label__value--low' : severity === 2 ? 'severity-label__value--medium' : 'severity-label__value--high'
    ]">
      {{ severity === 1 ? 'Low 😊' : severity === 2 ? 'Medium 😐' : 'High 🫠' }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'SeverityLabel',
  props: {
    severity: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped>
.severity-label {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.severity-label__label {
  margin-right: 1em;
  font-weight: bold;
}
.severity-label__value {
  display: inline-block;
  padding: 0.25em 0.5em;
  border-radius: 0.25em;
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  line-height: 1;
}
.severity-label__value--low {
  background-color: #2ecc71;
  color: #fff;
}
.severity-label__value--medium {
  background-color: #f39c12;
  color: #fff;
}
.severity-label__value--high {
  background-color: #e74c3c;
  color: #fff;
}
</style>
