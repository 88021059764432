<!-- A quick briefing on this web app: 

SmoothRide - Codename "InfrastructureGPT" is a platform that allows cycling citizens to upload experiences from their rides
Experiences can be nuisances or positive examples they would like to see in more places.
Assisted by AI users can then ideate on their submission and help their region improve their cycling infrastructure.
This project is carried out by Phil Wornath in the course of his master's project. Data is used for research purposes only in a pseudonomized nature.

ImageUpload Process: 
- User registers and logs in (Optional)
- User uploads an image
- Geo-Location is extracted from the image
- an image detection algoritm detects objects in the provided image
- Users can provide additional labels to train the image detection model
- User adds comment to the submission for further context
- AI generates a list of possible improvements
- User can rate the suggestions or generate new ones
- User can reflect to the suggestions and propose a solution
- user can submit the report

In the ExploreFeed view, users can view
- Receive content recommendations per submission
- a city overview with the most reported issues in comparison to other cities
- the submissions on a map

Technology Stack:
- Frontend based on Vue3, 
- Backend based on Flask / Python, OpenCV, PyTorch, YOLOV8, OpenAI GPT-3.5 and a custom backend based on Flask / Python
- Data stored in a self-hosted PocketBase instance hosted in Germany
- Users are in charge of their own data which can be deleted autonomously at any time using the "Delete Account" button in the user dashboard
 -->

<template>
  <div class="app">
    <!-- <MapComponent class="map-background" /> -->
    <div class="app-content">
      <HeaderSection />
      <ErrorBanner :errorMessage="error" />
      <router-view />
      <BottomNavBar />
    </div>
  </div>
</template>

<script>
import HeaderSection from "@/components/HeaderSection.vue";
import ErrorBanner from "@/components/ErrorBanner.vue";

// import SubmissionForm from "@/views/SubmissionForm.vue";
// import MapComponent from "@/components/MapComponent.vue";
// import FooterSection from "@/components/FooterSection.vue";
import BottomNavBar from "@/components/BottomNavBar.vue";

export default {
  name: "App",
  components: {
    HeaderSection,
    // SubmissionForm,
    // MapComponent,
    // FooterSection,
    ErrorBanner,
    BottomNavBar,
  },

  data() {
    // Define data properties
    return {
      error: null,
    };
  },
};
</script>

<style>
:root {
  /* Main color */
  --color-main: #41c4ab;
  --color-not-just-white: #fcfcfc;

  /* Primary Colors */
  --color-primary: var(--color-main);
  --color-primary-light: #88e2d1;
  --color-primary-dark: #1e9b87;
  --color-primary-transparent: rgba(65, 196, 171, 0.15);

  /* Complementary Colors */
  --color-complementary: #c44189;
  --color-complementary-light: #e288c7;
  --color-complementary-dark: #9b1e67;
  --color-complementary-transparent: rgba(196, 65, 137, 0.15);

  /* Tertiary Colors */
  --color-tertiary: #c4a141;
  --color-tertiary-light: #e2d188;
  --color-tertiary-dark: #9b871e;
  --color-tertiary-transparent: rgba(196, 161, 65, 0.15);

  /* Secondary Colors */
  --color-secondary: #999999;
  --color-secondary-light: #dddddd;
  --color-secondary-dark: #666666;

  /* Neutral Colors */
  --color-text: #333;
  --color-text-light: #f1f1f1;
  --color-text-dark: #000;
  --color-background: #f7f7f7;
  --color-background-light: #f2f2f2;
  --color-background-dark: #2a2a2a;
  --color-background-dark-medium: #3b3b3b;
  --color-background-dark-transparent: #2a2a2ae3;
  --color-stickynote: #f8e7b4;

  /* Warning Colors */
  --color-warning: #ffa500;
  --color-warning-light: #ffdab9;
  --color-warning-dark: #ff8c00;

  /* Error Colors */
  --color-error: #e74c3c;
  --color-error-light: #f2dede;
  --color-error-dark: #c0392b;
}

body {
  /* margin: 0; */
  width: auto;
  margin: 0;

  background-color: var(--color-background);
  transition: background-color 0.2s ease-in-out;

  color: var(--color-text-dark);
  font-family: "Poppins", sans-serif;

  /* font-family: Lato, sans-serif; */
  /* display: flex;
  /* justify-content: space-around; */
  align-items: center;
  text-align: center;
}

body.dark-mode {
  background-color: var(--color-background-dark);
  color: var(--color-text-light);
}

svg {
  fill: var(--primary-color);
  stroke: var(--secondary-color);
}

.dark-mode svg {
  fill: var(--color-text-light);
  stroke: var(--color-text-light);
}

p.fineprint {
  font-size: smaller;
  color: var(--color-secondary);
}

a {
  text-decoration: none;
  color: var(--color-primary);
}
.dark-mode a {
  color: var(--color-text-light);
}

.dark-mode h1 {
  color: var(--color-text-light);
}

h1 {
  font-weight: 400;
  margin-bottom: 12px;
  padding: 0;
  margin-top: 0;
  text-align: center;
  font-size: 1.5em;
  color: var(--color-text);
}

h2 {
  font-weight: 400;
  margin-bottom: 12px;
  padding: 0;
  margin-top: 0;
  text-align: center;
  /* color: var(--color-text); */
}

.dark-mode h2 {
  color: var(--color-text-light);
}

h3 {
  color: var(--color-text);
}

.dark-mode h3 {
  color: var(--color-text-light);
}
/* Define the animation for the underline */
@keyframes slide-in {
  0% {
    width: 0;
    opacity: 0;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
}

/* Apply the animation to the .underline-primary class */
.underline-primary {
  position: relative;
  text-decoration: none;
}

.underline-primary::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--color-primary);
  animation: slide-in 1.5s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.app {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* box-sizing: border-box; */
  /* height: 100vh; */
  margin: auto;
  max-width: 800px;
}

.dark-mode .container {
  background-color: var(--color-background-dark-medium);
}
.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  animation: fade-in 0.5s ease-out;
}

.container {
  display: flex;
  /* width: 100%; */
  /* max-width: 800px; */
  background-color: var(--color-not-just-white);
  padding: 32px;
  /* margin: 0 24px; */
  border-radius: 12px;
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.08);
  justify-content: center;
  flex-direction: column;
  overflow-x: visible;
  gap: 32px;
  z-index: 2;
  animation: fade-in 0.5s ease-out;
  margin-bottom: 64px;
}

.auth-form input[type="text"][data-v-3a5d5944],
.auth-form input[type="password"][data-v-3a5d5944] {
  padding: 12px;
  border: none;
  margin: 8px 0;
  border-bottom: 1px solid #ccc;
  font-size: 18px;
  background: var(--ol-background-color);
  border-radius: 4px;
}


/* list of single-purpose classes  */
.fade-in {
  animation: fade-in 0.5s ease-out;
}

.complementary {
  background-color: var(--color-complementary) !important;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* .dark-mode exif-info{
  color: var(--color-text-light);
} */

.dark-mode .form {
  background-color: var(--color-secondary-dark);
}

.dark-mode .file-picker-label {
  border-color: #ccc;
}

.dark-mode .comment textarea,
.dark-mode div.labels > form > input[type="text"] {
  background-color: #333;
  color: white;
  border-color: #555;
}

.error-section {
  background-color: #ffcccc;
  border: 1px solid #ff0000;
  padding: 10px;
  margin-top: 10px;
}

.btn-cta {
  background-color: var(--color-primary);
  color: #ffffff;
  font-size: 18px;
  padding: 12px 30px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.btn-cta:hover {
  background-color: var(--color-primary-dark);
}

.btn-cta:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn-standard {
  background-color: var(--color-background);
  color: var(--color-text);
  font-size: 18px;
  border: 1px solid;
  padding: 12px 30px;
  border-radius: 30px;
  /* border: none; */
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.btn-standard:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn-standard:hover {
  background-color: var(--color-secondary);
  color: var(--color-text-light);
}

.btn-round {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--color-not-just-white);
  /* color: var(--color-text); */
  color: var(--color-text);
  cursor: pointer;
  font-size: 24px;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.btn-round:hover {
  background-color: var(--color-background);
}
.map-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.app-content {
  position: relative;
  z-index: 1;
}

.success-metric {
  background-color: var(--color-primary);
  color: var(--color-not-just-white);
  padding: 24px;
  border-radius: 16px;
  display: flex;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  flex-direction: column;
  align-items: center;
}
.success-metric h2 {
  margin-top: 0;
}

.success-metric p {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.success-metric a {
  color: var(--color-not-just-white);
  /* bold font and underlined*/
  font-weight: 700;
  text-decoration: underline;
}

.comment textarea {
  border: none;
  border-radius: 12px;
  padding: 20px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  width: 100%;
  box-sizing: border-box;
  resize: vertical;
  background-color: #f4f4f4;
  transition: all 0.5s ease-in-out;
}

.comment textarea:focus {
  outline: none;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(
    45deg,
    rgba(0, 187, 255, 0.1),
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.05)
  );
  /* animation: spin 2s linear infinite; */
}

.report-card {
  display: flex;
  /* align-items: center; */
  /* padding: 10px; */
  /* justify-content: space-between; */
  padding: 24px;
  background-color: var(--color-white);
  border-radius: 10px;
  box-shadow: 0 0 40px rgb(0 0 0 / 5%);
  transition: all 0.3s ease;
  text-align: left;
}

.report-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* .report-link {
  display: flex;
  gap: 8px;
  align-items: center;
  text-decoration: none;
  color: #333;
} */
.thumbnail {
  margin-right: 10px;
}

.thumbnail img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.dark-mode .report-card {
  /* background-color: var(-; */
  background-color: var(--color-background-dark);
}

.report-card:hover {
  /* transform: translateY(-5px); */
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  /* transform: scale(1.01); */
}

.report-card .title {
  font-size: 20px;
  font-weight: bold;
  /* color: var(--color-primary); */
}

.report-card .details {
  font-size: 14px;
  color: var(--color-secondary);
}
.report-card .comment {
  font-size: 20px;
  /* color: var(--color-text); */
}

/* .report-card .details span {
  margin-right: 10px;
} */

.report-card .button {
  background-color: var(--color-primary);
  color: white;
  font-size: 14px;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.report-card .button:hover {
  background-color: var(--color-primary-dark);
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 1.2em;
  }
  h2 {
    font-size: 1.2em;
  }
  .report-card {
    flex-direction: column;
  }

  .report-card .details {
    margin-top: 10px;
  }

  .report-card .button {
    margin-top: 10px;
  }

  .container {
    margin: 0;
    margin-bottom: 64px;
    gap: 8px;
  }
}
</style>
