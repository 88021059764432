<template>
  <div class="container">
  <h1 v-underline-primary>Explore Reports</h1>
  <div class="content">
    <div class="tabs">
      <button :class="{ active: activeTab === 'feed' }" @click="setActiveTab('feed')">Feed</button>
        <button :class="{ active: activeTab === 'city' }" @click="setActiveTab('city')">City</button>
        <button :class="{ active: activeTab === 'country' }" @click="setActiveTab('country')">Country</button>
        <button :class="{ active: activeTab === 'map' }" @click="setActiveTab('map')">Map</button>
    </div>
    <div v-if="activeTab === 'feed'">
      <div class="list-container content">
    <!-- Display top rated reports -->
    <h3>✨ Top Rated</h3>
    <!-- div for top reports -->  
    <div v-for="report in topRatedReports" :key="report.submission_id" class="report-card">
      <router-link :to="{ name: 'ViewSubmission', params: { id: report.submission_id } }">
        <div class="thumbnail">
              <!-- <img src="https://via.placeholder.com/50" alt="Placeholder Image" /> -->
            </div>
            <div class="report-info">
              <div class="comment">
                <h3>{{ report.comment.substring(0, 100) + (report.comment.length > 100 ? '...' : '') }}</h3>
              </div>
              <div class="details">
                <!-- <span><strong>🚥 Severity:</strong> {{ report.severity_level }}</span> -->
                <span><strong>📍</strong> {{ report.city }}, {{ report.region }}</span>
                <!-- <span><strong>👤 Submitted By:</strong> {{ report.submitter }}</span> -->
                <span><strong>🔖 </strong><span v-for="(label, index) in report.all_labels" :key="index"><strong> {{ label.label }}, </strong></span></span>
              </div>
            </div>
      </router-link>
    </div>

    <!-- Display most recent reports -->
    <h3>📆 Most Recent</h3>
    <div v-for="report in mostRecentReports" :key="report.submission_id" class="report-card">
      <router-link :to="{ name: 'ViewSubmission', params: { id: report.submission_id } }">
        <div class="thumbnail">
              <!-- <img src="https://via.placeholder.com/50" alt="Placeholder Image" /> -->
            </div>
            <div class="report-info">
              <div class="comment">
                <h3>{{ report.comment.substring(0, 100) + (report.comment.length > 100 ? '...' : '') }}</h3>
              </div>
              <div class="details">
                <!-- <span><strong>🚥 Severity:</strong> {{ report.severity_level }}</span> -->
                <span><strong>📍 </strong> {{ report.city }}, {{ report.region }}</span>
                <!-- <span><strong>👤 Submitted By:</strong> {{ report.submitter }}</span> -->
                <span><strong>🔖 </strong><span v-for="(label, index) in report.all_labels" :key="index"><strong> {{ label.label }}, </strong></span></span>
              </div>
            </div>
      </router-link>
    </div>
  </div>
    </div>
    <div v-else-if="activeTab === 'map'">
      <p>Coming soon!</p>
      <!-- Add your map component here -->
    </div>
    <div v-else-if="activeTab === 'city'">
      <div v-if="selectedCity">
        <button class="back-button" @click="selectedCity = null">
          <i class="fas fa-arrow-left"></i> Back
        </button>
        <h2 v-underline-primary>{{ selectedCity }}</h2>
        <div class="list-container content">
          <router-link v-for="report in reportsByCity[selectedCity]" :key="report.submission_id" :to="{ name: 'ViewSubmission', params: { id: report.submission_id } }" class="report-card">
            <div class="thumbnail">
              <!-- <img src="https://via.placeholder.com/50" alt="Placeholder Image" /> -->
            </div>
            <div class="report-info">
              <div class="comment">
                <h3>{{ report.comment }}</h3>
              </div>
              <div class="details">
                <span><strong>📍 Location:</strong> {{ report.city }}, {{ report.region }}</span>
                <span><strong>👤 Submitted By:</strong> {{ report.submitter }}</span>
                <span><strong>🔖 Labels: </strong><span v-for="(label, index) in report.all_labels" :key="index"><strong> {{ label.label }},</strong></span></span>
              </div>
            </div>
          </router-link>

        </div>
      </div>
      <div v-else>
        <div class="city-boxes ">
          <div v-for="city in cities" :key="city" class="city-box" @click="selectCity(city)">
            <h3>{{ city }}</h3>
            <p>{{ reportsByCity[city].length }} reports</p>
          </div>
        </div>
      </div>
    </div>

      <div v-if="activeTab === 'country'">
    <div v-if="selectedCountry">
      <button class="back-button" @click="selectedCountry = null">
        <i class="fas fa-arrow-left"></i> Back
      </button>
      <h2 v-underline-primary>{{ selectedCountry }}</h2>
      <div class="list-container content">
        <router-link v-for="report in reportsByCountry[selectedCountry]" :key="report.submission_id" :to="{ name: 'ViewSubmission', params: { id: report.submission_id } }" class="report-card">
          <div class="thumbnail">
            <!-- <img src="https://via.placeholder.com/50" alt="Placeholder Image" /> -->
          </div>
          <div class="report-info">
            <div class="comment">
              <h3>{{ report.comment }}</h3>
            </div>
            <div class="details">
              <span><strong>📍 Location:</strong> {{ report.city }}, {{ report.region }}</span>
              <span><strong>👤 Submitted By:</strong> {{ report.submitter }}</span>
              <span><strong>🔖 Labels: </strong><span v-for="(label, index) in report.all_labels" :key="index"><strong> {{ label.label }},</strong></span></span>
            </div>
          </div>
        </router-link>

      </div>
    </div>
    <div v-else>
      <div class="city-boxes ">
        <div v-for="country in countries" :key="country" class="city-box" @click="selectCountry(country)">
          <h3>{{ country }}</h3>
          <p>{{ reportsByCountry[country].length }} reports</p>
        </div>
      </div>
    </div>
  </div>

  </div>
  </div>
</template>

<script>
export default {
  name: "ExploreFeed",
  data() {
    return {
      activeTab: "feed",
      reports: [],
      cities: [],
      countries: [],
      regions: [],
      reportsByCity: {},
      reportsByCountry: {},   // add this line to define the reportsByCountry object
      selectedCity: null,
      selectedCountry: null, // Add this line

    };
  },
  created() {
    this.activeTab = this.$route.query.tab || "feed";
    this.fetchReports();
    
  },
    computed: {
    // Get the top 3 rated reports
    topRatedReports() {
      return [...this.reports].sort((a, b) => b.upvotes - a.upvotes).slice(0, 3);
    },
    // Get the most recent reports
    mostRecentReports() {
      return [...this.reports].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    }
  },

  methods: {
    async fetchReports() {
      try {
        
        const data = await this.$pb.collection('reports').getList(1, 50, {
          filter: `category ~ 'nuisance' && report_public = True`,
           sort: `-created,id`
        });
        // const data = await response.json();
        this.reports = data.items;
        this.groupReportsByCity();
        this.groupReportsByCountry();
      } catch (error) {
        console.error(error);
      }
    },
    groupReportsByCity() {
      this.reports.forEach((report) => {
        let city = report.city;

        // Check if the city exists or is empty, if so, assign "Other" as the city value
        if (!city || city.trim() === '') {
          city = 'Other';
        }

        if (!this.cities.includes(city)) {
          this.cities.push(city);
          this.reportsByCity[city] = [];
        }
        this.reportsByCity[city].push(report);
      });
    },
    groupReportsByCountry() {
      this.reports.forEach((report) => {
        const country = report.country;
        if (!this.countries.includes(country)) {
          this.countries.push(country);
          this.reportsByCountry[country] = [];
        }
        this.reportsByCountry[country].push(report);
      });
    },
    setActiveTab(tab) {
      this.activeTab = tab;
      this.$router.replace({ query: { tab } });
    },

    selectCity(city) {
      this.selectedCity = city;
    },
    selectCountry(country) {
  this.selectedCountry = country;
},

  },
};
</script>
<style scoped>

h3 {
  margin: 0;
  font-size: 16px;
}
.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.tabs button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  margin-right: 16px;
  padding: 8px 16px;
  border-radius: 30px;
  transition: all 0.3s ease;
}

.tabs button {
  color: var(--color-text);
}

.dark-mode .tabs button {
  color: var(--color-white);
}

.tabs button.active {
  background-color: var(--color-primary);
  color: white;
  transform: scale(1.1);
}

.city-boxes {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  animation: fade-in .5s ease-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.city-box {
  background-color: var(--color-secondary);
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 16px;
  border-radius: 8px;
  margin-right: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  transition: transform 0.3s ease;
}


.city-box:hover {
  background-color: var(--color-primary);
  transform: scale(1.05);
}

.active-city-box {
  background-color: var(--color-primary);
  transform: scale(1.05);
}

.back-button {
  display: flex;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
  color: var(--color-primary);
  font-weight: bold;
  transition: all 0.3s ease;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 20px;
}

.back-button:hover {
  transform: translateX(-4px);
}

.back-button i {
  margin-right: 8px;
}

.city-details {
  margin-top: 16px;
}

.city-details h3 {
  margin-bottom: 8px;
}

.list-container {
  gap:32px;
}


@media (max-width: 767px) {

  .tabs {
    transform: scale(.8);
  }

}

</style>
