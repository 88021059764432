// require('dotenv').config()
import { createApp } from 'vue'
import App from './App.vue'
import { errorHandler } from './errorHandler';
import store from './store.js';
import router from './router'
import pocketbase from '@/plugins/pocketbase';
import '@fortawesome/fontawesome-free/css/all.css'
import "@fontsource/poppins"; // Defaults to weight 400.
import "@fontsource/poppins/100.css"; // Specify weight


import OpenLayersMap from "vue3-openlayers";
import "vue3-openlayers/dist/vue3-openlayers.css";



const app = createApp(App)
app.use(store);
app.use(router);
app.use(pocketbase);
app.provide('appName', 'SmoothRide');


app.directive('underline-primary', {
    // When the bound element is inserted into the DOM...
    mounted(el) {
      // Find the first word of the h2 text
      const text = el.innerText
      const firstWord = text.match(/^[^\s]+/)[0]
  
      // Wrap the first word in a span with the "underline-primary" class
      const span = document.createElement('span')
      span.classList.add('underline-primary')
      span.innerText = firstWord
  
      // Replace the first word in the h2 with the span
      el.innerHTML = text.replace(firstWord, span.outerHTML)
    }
  })

app.config.errorHandler = errorHandler;

// app.config.errorHandler = (err, instance, info) => {
//   // report error to tracking services
// }
app.use(OpenLayersMap); 
app.mount('#app')


router.afterEach((to) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
})