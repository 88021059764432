<template>
    <div class="severity-scale fade-in">
      <div class="scale">
        <div
          v-for="(option, index) in options.items"
          :key="index"
          class="scale-item"
          :class="{
            active: selected === index,
            [option.color]: true
          }"
          @click="selectSeverity(index)"
          :title="option.tooltip"
        >
          <div class="label">{{ option.label }}</div>
        </div>
      </div>
  
      <div class="examples">
        <div v-if="selected !== null" class="example">
          <div :class="'severity-dot ' + options.items[selected].color"></div>
          <div class="example-text">{{ options.items[selected].example }}</div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      modelValue: {
        type: Number,
        default: null
      }
    },
    data() {
      return {
        selected: null,
        options: {
          items: [
            {
              label: 'Low',
              tooltip: 'Praise or compliment',
              color: 'green',
              example: 'Praise or compliment.'
            },
            {
              label: 'Medium',
              tooltip: 'Temporary nuisance',
              color: 'yellow',
              example: 'Medium or temporary nuisance.'
            },
            {
              label: 'High',
              tooltip: 'Permanent high nuisance',
              color: 'red',
              example: 'Permanent high nuisance or dangerous situation'
            }
          ]
        }
      };
    },
    watch: {
      modelValue(newValue) {
        this.selected = newValue;
      }
    },
    methods: {
      selectSeverity(severity) {
        this.selected = severity;
        this.$emit('update:modelValue', severity);
      }
    }
  };
  </script>
  
  <style scoped>
  .severity-scale {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    
  }

  
  
  h2 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
    color: #3c3c3c;
  }
  
  .scale {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 32px;
    width: 200px;
    margin-bottom: 10px;
  }
  
  .scale-item {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out;
  }
  .scale-item:hover {
    transform: scale(1.3);
}
  
  .scale-item:active {
    transform: scale(1.2);
}
  
  .green {
    background-color: #66bb6a;
  }
  
  .yellow {
    background-color: #ffc107;
  }
  
  .red {
    background-color: #f44336;
  }
  
  .active {
    transform: scale(1.3);
  }
  
  .labels {
    display: flex;
    justify-content: space-between;
    width: 300px;
  }
  
  .label {
    font-size: 12px;
    text-align: center;
    display: flex;
    margin-top: -25px;
    color: var(--color-text);
    justify-content: center;
  }
  .dark-mode .label {
    color: var(--color-text-light);
  }
  
  .examples {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    height: 50px;

  }
  
  .example {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
    width: 300px;
  }
  
  .severity-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .green-dot {
    background-color: #66bb6a;
  }
  
  .yellow-dot {
    background-color: #ffc107;
  }
  
  .red-dot {
    background-color: #f44336;
  }
  
  .example-text {
    font-size: 14px;
    color: var(--color-text);
  }
  
.dark-mode .example-text {
    font-size: 14px;
    color: var(--color-text-light);
  }
  
  @media only screen and (max-width: 480px) {
    .scale {
      width: 200px;
    }
  
    .labels {
      width: 200px;
    }
  
    .label {
      font-size: 10px;
      width: 25px;
    }
  
    .example {
      width: 200px;
    }
  }
</style>
