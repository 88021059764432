<template>
  <div class="container">
  

    <div v-if="!loggedIn" >
        <UserLogin />
    </div>

    <div class="dashboard-header">
      <router-link to="/settings">
        <button class="btn-round">
          <i class="fas fa-cog"></i>
        </button>
      </router-link>
      <div v-if="loggedIn" class="user-header">
        <img :src="userAvatar" alt="User Avatar" />
        <h2  v-underline-primary >Welcome, {{ user.model.username }}!</h2>
        <p>Email: {{ user.model.email }}</p>

        <p>🪙 Points: {{ userPoints }}</p>
      </div>
    </div>


    <div v-if="loggedIn" class="content fade-in">
    <div v-if="reportSubmissions" class="success-metric fade-in">
      <h2>Your Impact</h2>
      <p>You have reported {{ reportSubmissions.length }} bicycle nuisances in {{ cities.length > 1 ? cities.join(', ') : cities[0] }} so far!</p>
      <p>Your reports have helped creating more bike-friendly cities.</p>
    </div>
    <div class="activity">
  <h2>Recent Activity</h2>
  <div class="list-container content">
  <div v-for="report in reportSubmissions" :key="report.submission_id" class="report-card">
    <router-link v-if="!editMode" :to="{ name: 'ViewSubmission', params: { id: report.submission_id } }"  :class="{ disabled: report.editMode }">
      <div class="details">
        <span><strong>🆔:</strong> {{ report.submission_id }} </span>
        <span><strong>📆:</strong> {{ report.timestamp }} </span>
        <span><strong>📍 Location:</strong> {{ report.city }}, {{ report.region }} </span>
      </div>
      <div class="report-info">
          <p v-if="!report.editMode">{{ report.comment }}</p>
      </div>
    </router-link>
    <div v-if="report.editMode" class="comment fade-in">
      <textarea v-model="report.newComment"></textarea>
    </div>
    <div class="submission-buttons">
      <button class="btn-round edit" v-if="!report.editMode" @click="editComment(report)">📝</button>
      <button class="btn-round save" v-if="report.editMode" @click="saveComment(report)">💾</button>
      <button class="btn-round cancel" v-if="report.editMode" @click="cancelEdit(report)">❌</button>
      <button class="btn-round toggle-visibility" @click="toggleReportVisibility(report)" :disabled="report.editMode">{{ report.report_public ? '✅' : '🕵️‍♀️' }}</button>
      <button class="btn-round delete" @click="deleteSubmission(report)" :disabled="report.editMode">🗑️</button>
    </div>
  </div>
</div>



</div>

  </div>
    

  </div>
</template>

<script>

import UserLogin from '@/components/UserLogin.vue';
import { mapState, mapActions } from 'vuex';


export default {
  name: "UserDashboard",

  components: {
    UserLogin,
  },

  data() {
    return {
      user: null,
      challenges: [
      ],
      userChallenges: [
      ],
      reportSubmissions: null,
      shuffledChallenges: null,
      cities: null,
      currentChallengeIndex: 0,

      selectedChallenges: [], // Initialize to an empty array
      newComment: '',


    };
  },
  computed: {
    ...mapState(['user']),
    loggedIn() {
    return !!localStorage.getItem('pocketbase_auth') || !!this.token;
  },
    recentSubmissions() {
    return this.user.submissions.slice(0, 5);
    },
    uniqueCities() {
    return [...new Set(this.reportSubmissions.map(report => report.city))];
  },
  cityCount() {
    return this.uniqueCities.length;
  },

    userAvatar() {
      if (this.user.model.avatar) {
        return `${process.env.VUE_APP_PB_URL}/api/files/_pb_users_auth_/${this.user.model.id}/${this.user.model.avatar}`;
      } else {
        return `https://ui-avatars.com/api/?name=${this.user.model.username}&background=random&size=200`;
      }
},

    userPoints() {
      return this.user.model.points || 0;
    },


  },

  mounted() {
    const params = new URL(window.location.href).searchParams;
    const code = params.get('code');
    const provider = JSON.parse(localStorage.getItem('provider'));

    if (code && provider) {
      this.authenticateWithCode(provider.name, code, provider.codeVerifier);
    }


  },

  created(){

    if (localStorage.getItem('pocketbase_auth')) {
      // Retrieve the user data from the authStore
      this.user = JSON.parse(localStorage.getItem('pocketbase_auth'));
    }

    // fetch challenge data from the authStore
    this.getReports();


  },
  watch: {
    loggedIn(newValue) {
      console.log('loggedIn changed to', newValue);
      if (newValue) {
        window.location.reload();
      }
    }
  },


  methods: {

    ...mapActions(['logout', 'updateUser']),
    onLogoutClick() {
        this.logout({ $pb: this.$pb });
        this.$router.push('/');
      },


      async authenticateWithCode(providerName, code, codeVerifier) {
        const redirectPath = '/profile';
        const redirectUrl = `${process.env.VUE_APP_URL}${redirectPath}`;

          try {
            const authData = await this.$pb.collection('users').authWithOAuth2Code(
              providerName,
              code,
              codeVerifier,
              redirectUrl,
              {
                emailVisibility: false,
              }
            );
            console.log(authData);
            window.location.reload();

            // Perform any necessary actions with the authentication data
          } catch (error) {
            console.error(error);
            // Handle error
          }
        },

      async getReports() {
        const userId = this.user.model.id;
        const resultList = await this.$pb.collection('reports').getList(1, 50, {
          filter: `user_og ~ '${userId}'`,
          sort: '-created',
        });
        // Set the result to the data property
        this.reportSubmissions = resultList.items;
        this.cities = [...new Set(this.reportSubmissions.map(report => report.city))];
      },

      async editComment(submission) {
        submission.newComment = submission.comment;
        submission.editMode = true;
      },
      cancelEdit(submission) {
        submission.editMode = false;
      },



  async deleteSubmission(submission) {
    // Display a confirmation dialog to the user
    const confirmed = confirm(`Are you sure you want to delete the submission "${submission.title}"?`);
    if (!confirmed) {
      return;
    }

    try {
      // Delete the submission record from PocketBase
      await this.$pb.collection('reports').delete(submission.id);

      // Remove the submission from the list in the component's data
      const index = this.reportSubmissions.indexOf(submission);
      this.reportSubmissions.splice(index, 1);
    } catch (error) {
      console.error(error);
      alert('An error occurred while deleting the submission. Please try again later.');
    }
  },
  async toggleReportVisibility(report) {
  try {
    // Toggle the report_public field in PocketBase
    const newVisibility = !report.report_public;
    await this.$pb.collection('reports').update(report.id, { report_public: newVisibility });

    // Update the report_public field in the component's data
    report.report_public = newVisibility;
  } catch (error) {
    console.error(error);
    alert('An error occurred while updating the report visibility. Please try again later.');
  }
},
async saveComment(submission) {
  try {
    // Update the submission record in PocketBase
    await this.$pb.collection('reports').update(submission.id, { comment: submission.newComment });

    // Update the comment field in the component's data
    submission.comment = submission.newComment;
    submission.newComment = '';
    submission.editMode = false;
  } catch (error) {
    console.error(error);
    alert('An error occurred while updating the comment. Please try again later.');
  }
},





  },

};
</script>

<style scoped>

.dashboard-header {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  /* margin-bottom: 24px; */
}

.user-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: -48px auto 0 auto;

}

.user-header img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  object-fit: cover;
  object-position: center;
}
.activity {
  margin-bottom: 24px;
}


.personal-challenge-box h4 {
  margin: 4px 0;
}

.activity li {
  margin-bottom: 8px;
}

.report-card {
  flex-direction: column;
}

.submission-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
</style>
